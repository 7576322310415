<template>
    <div>
        <div v-if="errorMessage.length">
            {{errorMessage}}
        </div>

        <template v-if="loaded">

            <div class="topbar topbar--full">
                
                <router-link to="/trackers" class="link-back">All Trackers</router-link>
                
                <h2 class="tracker-name heading2">
                    {{ info.name }}
                </h2>
                
                <div class="topbar__wrap">
                    <input class="form-search-primary" v-model="search" v-debounce:500="updateSearch" placeholder="Search for a tracker" type="text"/>
                    
                    <div class="topbar__inner mb-0 pt-2">
                        <div class="topbar__key">
                            <div class="topbar__key__footer">
                                <span class="topbar__key__label">Filter by:</span>
        
                                <template v-for="(label, value) in filterTypes1">
                                    <div v-if="value != 'related-to-me' || $store.state.user.is_admin" :key="value" class="key__filter" @click="setFilterType1(value)">
                                        <span class="key__filter__wrapper">
                                            <span class="key__filter__tag key__filter__tag--classic" :class="{key__filter__connection: filterType1 != value}">
                                                <span>{{label}}</span>
                                            </span>
                                        </span>
                                    </div>
                                </template>
                            </div>
                        </div>
    
                        <div class="topbar__side">
                            <template v-if="info.type === trackerTypes.PUBLIC">
                                <a v-if="linkCopied" class="copy-link mb-0 mr-3">Link copied</a>
                                <a v-else class="copy-link mb-0 mr-3" @click="copyPublicLink">Copy Public Link</a>
                            </template>

                            <ul class="actions-list">
                                <li>
                                    <div>
                                        <b-dropdown v-if="arrayHelper.inArray(info.user_permissions, 'tracker-folder-manage') || arrayHelper.inArray(info.user_permissions, 'tracker-folder-add-trackers')" id="dropdown-1" text="Actions" class="topbar__side__actions icons-list dropdown-simple">

                                            <template v-if="arrayHelper.inArray(info.user_permissions, 'tracker-folder-manage')">
                                                <b-dropdown-item class="action icons-list__edit" @click="edit">
                                                    Edit Folder
                                                </b-dropdown-item>

                                                <b-dropdown-item class="action icons-list__delete" @click="remove">
                                                    Delete
                                                </b-dropdown-item>

                                                <b-dropdown-item class="action icons-list__archive" v-if="!info.archived" @click="archive">
                                                    Archive
                                                </b-dropdown-item>

                                                <b-dropdown-item class="action icons-list__archive" v-else @click="unarchive">
                                                    Unarchive
                                                </b-dropdown-item>
                                            </template>
    
                                            <b-dropdown-item v-if="arrayHelper.inArray(info.user_permissions, 'tracker-folder-add-trackers')" class="action icons-list__add" @click="openAddTrackers">
                                                Add Trackers
                                            </b-dropdown-item>
    
                                        </b-dropdown>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <div class="main-inner">
            <div class="tracker-folder-description" v-if="info.type === trackerTypes.PUBLIC && info.description">
                <p>{{info.description}}</p>
            </div>

            <TrackersList
                ref="trackersList"
                :filter-type1="filterType1"
                :folder-id="id"
                :search="search"
                :user-can-add-tracker="userCanAddTracker"
                :hide-filter="info.type === 'public'"
                @add="$refs.editTracker.open(0)"
                @edit="(trackerId) => $refs.editTracker.open(trackerId)"
                @removeFromFolder="removeFromFolder"
                @remove="removeTracker"
                @archive="archiveTracker"
                @unarchive="unarchiveTracker"
            />
        </div>

        <editFolder
            ref="editFolder"
            v-on:folderSaved="getInfo"
        >
        </editFolder>

        <edit-tracker
            ref="editTracker"
            :folder-id="id"
            :folder-type="info.type"
            @trackerSaved="getData(); fetchTrackers(true)"
        >
        </edit-tracker>

        <add-trackers
            ref="addTrackers"
            :to-folder="true"
            :trackers-list="trackersToAdd"
            v-on:trackersSelected="addTrackers"
        >
        </add-trackers>

        <helper v-if="$store.getters.isEnterprise"
                page="trackers"
        />

    </div>
</template>

<script>

import trackers from "@/services/trackers.service";
import trackersFolders from "@/services/trackers-folders.service";
import editFolder from "@/components/trackers/EditFolder"
import editTracker from "@/components/trackers/trackerEdit/TrackerEdit.vue";
import AddTrackers from "@/components/manage/SelectTrackers";
import user from "@/services/user.service";
import {mapActions} from "vuex";
import TrackersList from "@/components/trackers/TrackersList";
import {trackerTypes} from "@/constants/trackers";
import Helper from "@/components/Helper.vue";

export default {
    data() {
        return {
            id: parseInt(this.$route.params.id),

            info: {
                name: '',
                type: '',
                description: '',
                referral_url: '',
                archived: false,
                permissions: [],
                user_permissions: [],
                tracker_ids: [],
            },

            loaded: false,
            linkCopied: false,
            errorMessage: "",

            search: "",

            filterType1: "all",

            trackersToAdd: [],

            filterTypes1: {
                'all': 'All Trackers',
                'created-by-me': 'Created By Me',
                'related-to-me': 'Related To Me',
            },
        }
    },

    components: {
        Helper,
        TrackersList,
        editFolder,
        editTracker,
        AddTrackers
    },

    mounted() {
        this.getInfo()

        this.$store.watch(state => {
            if (state.user.settings.trackers_filter) {
                this.filterType1 = state.user.settings.trackers_filter
            }
            else if (state.user.is_admin) {
                this.filterType1 = "related-to-me"
            }

            if (state.needUpdateTrackers) {
                this.getTrackersList()
            }
        })

        this.getData()
    },

    watch: {
        filterType1() {
            this.getTrackersList()
        },
    },

    computed: {
        trackerTypes() {
            return trackerTypes
        },
        userCanAddTracker() {
            return this.$store.state.user.is_admin ||
            (this.arrayHelper.inArray(this.$store.state.user.permissions, 'tracker-create')
                && this.arrayHelper.inArray(this.info.user_permissions, 'tracker-folder-add-trackers'))
        }
    },

    methods: {
        ...mapActions(['fetchTrackers']),

        getInfo() {
            trackersFolders.getInfo(this.id).then(response => {
                this.info = response.data
                document.title = this.info.name + ' | SwitchPitch'
                this.loaded = true
            }).catch(error => {
                this.errorMessage = error.response.data.message
            })
        },

        updateSearch() {
            this.getTrackersList()
        },

        getData() {
            this.getNumbers()
        },

        getNumbers() {
            this.$refs.trackersList.getTrackersNumber()
        },

        getTrackersList() {
            this.$refs.trackersList.getTrackersList()
        },

        archive() {
            if (this.$refs.trackersList.trackersTotalCount.active > 0) {
                if (confirm("Archive folder and all it's trackers?")) {
                    trackersFolders.archive(this.id).then(() => {
                        this.getData()
                        this.getInfo()
                    })
                }
            }
            else {
                trackersFolders.archive(this.id).then(() => {
                    this.getData()
                    this.getInfo()
                })
            }
        },

        unarchive() {
            if (this.$refs.trackersList.trackersTotalCount.archived > 0) {
                if (confirm("Unarchive folder and all it's trackers?")) {
                    trackersFolders.unarchive(this.id).then(() => {
                        this.getData()
                        this.getInfo()
                    })
                }
            }
            else {
                trackersFolders.unarchive(this.id).then(() => {
                    this.getData()
                    this.getInfo()
                })
            }
        },

        remove() {
            if (this.$refs.trackersList.trackersTotalCount.active > 0 || this.$refs.trackersList.trackersTotalCount.archived > 0) {
                if (confirm("Delete folder and all its trackers?")) {
                    trackersFolders.delete(this.id).then(() => {
                        this.$router.push('/trackers')
                    })
                }
            }
            else {
                trackersFolders.delete(this.id).then(() => {
                    this.$router.push('/trackers')
                })
            }
        },

        edit() {
            this.$refs.editFolder.open(this.id)
        },

        removeTracker(id) {
            trackers.delete(id).then(() => {
                this.getData()
            })
        },

        archiveTracker(id) {
            trackers.update(id, {status: 'archive'}).then(() => {
                this.getData()
            })
        },

        unarchiveTracker(id) {
            trackers.update(id, {status: 'draft'}).then(() => {
                this.getData()
            })
        },

        openAddTrackers() {
            trackers.getList(this.info.type === trackerTypes.PUBLIC ? 'public' : '').then(response => {
                let trackerIds = this.info.tracker_ids.map(item => parseInt(item))
                this.trackersToAdd = response.data.filter(item => !trackerIds.includes(item.value))
                this.$refs.addTrackers.open()
            })
        },

        addTrackers(addedTrackers) {
            let trackerIds = this.arrayHelper.arrayColumn(addedTrackers, 'id')
            trackersFolders.addTrackers(this.id, trackerIds).then(() => {
                this.getInfo()
                this.getData()
            })
        },

        setFilterType1(filterType) {
            user.saveTrackerFilter(filterType).then(response => {
                this.$store.commit('setUser', response.data)
            })
        },

        copyPublicLink() {
            let link = document.location.origin + '/public-collections/' + this.info.referral_url
            navigator.clipboard.writeText(link)
            this.linkCopied = true;

            setTimeout(() => {
                this.linkCopied = false;
            }, 2000);
        },

        removeFromFolder(id) {
            if (confirm("Remove tracker from folder?")) {
                trackers.update(id, {folder_id: null}).then(() => {
                    this.getData();
                })
            }
        },
    }
}

</script>

<style scoped>

</style>
