<script setup>
import Vue, {computed, onMounted, provide, ref, watch} from "vue";
import ecosystemsService from "@/services/ecosystems.service";
import {useRoute, useRouter} from "vue2-helpers/vue-router";
import ProfileTopBar from "@/views/profile/ProfileTopBar.vue";
import {useStore} from "vue2-helpers/vuex";
import ErrorReport from "@/components/AddErrorReport.vue";
import InviteOrConnectEcosystem from "@/components/ecosystem/InviteOrConnectEcosystem.vue";
import tasksService from "@/services/tasks.service";
import AccountTypes from "@/constants/accountTypes";
import ProfileSideBar from "@/views/profile/ProfileSideBar.vue";
import ProfileActivities from "@/views/profile/ProfileActivities.vue";
import interactionsService from "@/services/interactions.service";
import accountTypes from "@/constants/accountTypes";
import ArticlesModal from "@/components/articles/ArticlesModal.vue";
import StartupsList from "@/components/startups/StartupsList.vue";
import VueMultiselect from "vue-multiselect";

const store = useStore();
const route = useRoute();
const router = useRouter();
const id = parseInt(route.params.id);
const commonData = ref(null);
const errorReportModal = ref(null);
const inviteModal = ref(null);
const tasksListType = ref('all');
const currentTab = ref(1);
const connectionStatus = ref(null);
const latestActivities = ref(null);
const newsModal = ref(null);
const startupsList = ref(null);
const startupsSearchText = ref('');
const startupsSearchQuery = ref('');
const allStartupsCategories = ref([]);
const selectedStartupsCategories = ref([]);

watch(() => store.getters.isEnterprise, (value) => {
    if (value) {
        getLatestActivities();
    }
}, {immediate: true});

onMounted(() => {
    getCommonData();
});

async function getCommonData() {
    const {data} = await ecosystemsService.getCommonData(id);

    commonData.value = data;
    connectionStatus.value = commonData.value.connect_status;
    document.title = commonData.value.name + ' | SwitchPitch';
}

async function getStartupsCategories() {
    const {data} = await ecosystemsService.getStartupsCategories(id);
    allStartupsCategories.value = data;
}

async function resolveConnectionRequest(status) {
    const {data} = await ecosystemsService.setConnectionRequestStatus(id, status);

    if (data) {
        let [status, hasAccess] = data;
        connectionStatus.value = status;

        Vue.notify({
            group: 'app',
            type: 'success',
            duration: 3000,
            text: "Invitation " + status
        })

        if (!hasAccess) {
            router.push('/');
        }
    } else {
        Vue.notify({
            group: 'app',
            type: 'error',
            duration: 3000,
            text: "Error occurred"
        })
    }
}

function tabChanged(tab) {
    currentTab.value = tab;

    if (tab === 2) {
        startupsList.value.initList();

        if (!allStartupsCategories.value.length) {
            getStartupsCategories();
        }
    }
}

async function getConnectionStatus() {
    const {data} = await ecosystemsService.getConnectionStatus(id);
    connectionStatus.value = data;
}

function getTasksCount() {
    return ecosystemsService.getTasksCount(id);
}

function getTrackersIds() {
    return ecosystemsService.getTrackersIds(id);
}

function getTrackersIndexList(pageParams) {
    return ecosystemsService.getTrackersIndex(id, pageParams);
}

function getTasksIndexList(listType, data, pageParams) {
    return tasksService.getIndex(listType, 0, 0, id, data, pageParams);
}

function getAvailableRecipients () {
    return ecosystemsService.getAvailableRecipients(id);
}

function getContactsForSidePanel() {
    return ecosystemsService.getContactsForSidePanel(id);
}

function getContactsIndexList(search, pageParams) {
    return ecosystemsService.getContactsIndex(id, search, pageParams);
}

async function getLatestActivities() {
    const {data} = await ecosystemsService.getActivities(id, {
        sort: 'created_at',
        limit: 3
    });

    latestActivities.value = data;
}

function saveInteraction(interactionId, interaction) {
    return interactionsService.save(interactionId, accountTypes.ECOSYSTEM, id, interaction);
}

function saveNote(noteId, note) {
    return ecosystemsService.saveNote(id, noteId, note);
}

function getNoteEdit(noteId) {
    return ecosystemsService.getNoteEdit(id, noteId);
}

function getActivities(data) {
    return ecosystemsService.getActivities(id, data);
}

function getNewsIndexList(pageParams) {
    return ecosystemsService.getNews(id, pageParams);
}

function getStartupsIndexList(data, pageParams) {
    return ecosystemsService.getStartups(id, data, pageParams);
}

provide('companyType', AccountTypes.ECOSYSTEM);
provide('companyId', id);
provide('companyName', computed(() => commonData.value.name));
provide('tasksListType', tasksListType);
provide('getTasksCount', getTasksCount);
provide('getTrackersIds', getTrackersIds);
provide('getTrackersIndexList', getTrackersIndexList);
provide('getTasksIndexList', getTasksIndexList);
provide('getAvailableRecipients', getAvailableRecipients);
provide('getContactsForSidePanel', getContactsForSidePanel);
provide('getContactsIndexList', getContactsIndexList);
provide('saveInteraction', saveInteraction);
provide('saveNote', saveNote);
provide('getNoteEdit', getNoteEdit);
provide('getActivities', getActivities);
provide('getNewsIndexList', getNewsIndexList);
provide('getStartupsIndexList', getStartupsIndexList);
provide('companyLogo', computed(() => commonData.value.logo_url));
</script>

<template>
    <div v-if="commonData">
        <ProfileTopBar
            company-type="ecosystem"
            :commonData="commonData"
            @reportIncorrectData="errorReportModal.open()"
            @inviteCompany="inviteModal.process(id)"
        />

        <InviteOrConnectEcosystem
            ref="inviteModal"
            @inviteSent="getConnectionStatus"
            @connectionEstablished="getConnectionStatus"
        />

        <ErrorReport
            ref="errorReportModal"
            :ecosystem-id="id"
        />

        <main class="main-startup">
            <ProfileSideBar
                :id="id"
                :commonData="commonData"
                :mobile-visible="currentTab === 0"
                @reportIncorrectData="errorReportModal.open()"
            >
                <template #status>
                    <template v-if="connectionStatus === 'none'">
                        <button v-if="$store.state.user.settings.enable_connections" class="button button--bordered--with--icon button--connect mb-3"
                                @click="inviteModal.process(id)">Connect
                        </button>
                    </template>

                    <template v-else-if="connectionStatus === 'enterprise-need-accept'">
                        <a
                            class="button button--bordered--with--icon button--connect mb-3"
                            @click="resolveConnectionRequest('accepted')"
                        >
                            Accept Invite
                        </a>

                        <a
                            class="button button--full button--red mb-3"
                            @click="resolveConnectionRequest('rejected')"
                        >
                            Reject
                        </a>
                    </template>

                    <template v-else-if="commonData.has_account && connectionStatus === 'new'">
                        <button class="button button--bordered--with--icon button--connect--pending mb-3 w-100">
                            Connection Pending
                        </button>
                    </template>

                    <template v-else-if="commonData.has_account && connectionStatus === 'accepted'">
                        <button class="button button--bordered--with--icon button--connect--accepted mb-3">
                            Connected
                        </button>
                    </template>
                </template>
            </ProfileSideBar>

            <div class="content">
                <b-tabs class="main-startup__tabs" @activate-tab="tabChanged">
                    <b-tab>
                        <template #title>
                            Overview
                        </template>
                    </b-tab>

                    <b-tab active>
                        <template #title>
                            About <span class="main-startup__tabs__hidden">Company</span>
                        </template>

                        <div
                            v-if="commonData.description"
                            class="main-startup__tabs__item"
                        >
                            <header class="main-startup__tabs__header">
                                <h4 class="heading4 heading4--tertiary">Description</h4>
                            </header>

                            <div class="main-startup__tabs__item__inner text-pre-wrap">{{commonData.description}}</div>
                        </div>

                        <ProfileActivities
                            ref="profileActivities"
                            :latestActivities="latestActivities"
                            @activitiesChanged="getLatestActivities"
                        />

                        <div class="main-startup__tabs__item" v-if="commonData.latest_news.length">
                            <header class="main-startup__tabs__header">
                                <h4 class="heading4 heading4--tertiary">News</h4>
                            </header>

                            <ul class="main-startup__tabs__item__inner news-funding-list">
                                <li>
                                    <div class="news-funding-list__title">
                                        Latest News
                                    </div>

                                    <div class="flex-fill">
                                        <ul class="news-funding-list__news">
                                            <li v-for="news in commonData.latest_news" :key="news.id">
                                                <div>
                                                    <p class="news-funding-list__news__domain"><a href="#">{{news.domain}}</a></p>
                                                    <h6 class="heading6"><a :href="news.url">{{news.title}}</a></h6>
                                                    <time class="news-funding-list__news__time">{{news.posted_on}}</time>
                                                </div>

                                                <fieldset v-if="news.image_url" class="image-block image-block--lg">
                                                    <img :src="news.image_url" :alt="news.title"
                                                    >
                                                </fieldset>
                                            </li>
                                        </ul>

                                        <div v-if="commonData.news_count > 5" class="mb-5 w-100 text-end">
                                            <a
                                                class="link link--view link--view--small"
                                                @click="newsModal.open()"
                                            >
                                                {{commonData.news_count - 5}} more news
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </b-tab>

                    <b-tab v-if="commonData.startups_count">
                        <template #title>
                            Startups
                        </template>

                        <a class="link link--view link--top" :href="`/startups?ecosystem=${encodeURIComponent(commonData.name)}`" target="_blank">Open in Search</a>

                        <div class="main-startup__tabs__item">
                            <header class="main-startup__tabs__header main-startup__tabs__header--startups">
                                <h4 class="heading4 heading4--tertiary">{{ commonData.name }}'s Startups ({{startupsList?.startupsCount}})</h4>
                                <input
                                    v-model="startupsSearchText"
                                    placeholder="Search Startups"
                                    style="width: 250px"
                                    type="text"
                                    class="form-search-primary"
                                    v-debounce:500ms.lock="() => {startupsSearchQuery = startupsSearchText}"
                                >

                                <div>
                                    <VueMultiselect
                                        v-model="selectedStartupsCategories"
                                        multiple
                                        placeholder="Category"
                                        :options="allStartupsCategories"
                                        label="name"
                                        track-by="id"
                                        class="multiselect-mid multiselect-dark pl-2"
                                    />
                                </div>

                            </header>

                            <div class="main-startup__tabs__item__inner">
                                <StartupsList
                                    ref="startupsList"
                                    :searchQuery="startupsSearchQuery"
                                    :searchCategories="selectedStartupsCategories.map(category => category.id)"
                                />
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </main>

        <ArticlesModal ref="newsModal"/>
    </div>
</template>

<style scoped>
.main-startup__tabs__header > :nth-child(2) {
    margin-left: auto;
    margin-right: 20px;
}

.form-search-primary {
    width: 1031px;
    border-radius: 8px;
    background: #043648 url(../../images/search.svg) no-repeat 10px center;
    background-size: 16px auto;
    border: solid 1px #ddd;
    color: #fff;

    &::placeholder {
        color: #fff;
    }
}

.multiselect.multiselect-mid {
    border-radius: 8px;
    min-width: 110px;
    max-width: 400px;
    min-height: 40px !important;
}
</style>
