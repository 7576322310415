<template>
    <div v-if="$store.state.user.is_admin">
        <div class="tracker-section tracker-section--inline">
            <h2 class="tracker-name heading2 w-100 mb-3">
                Team
            </h2>

            <input class="form-search-primary" v-model="search" v-debounce:500="getLists" placeholder="Search for a user" type="text"/>
        </div>

        <div class="main-inner">
            <header class="main-inner__header">
                <h2 class="heading2">Admins</h2>

                <a class="link" @click="addUser(true)">+ Add Admin</a>
            </header>

            <div class="main-inner__content">
                <ul class="list-contacts list-contacts--full">
                    <li v-for="user in users.admins" :key="user.id">
                        <div class="card card--bordered card--centered">

                            <b-dropdown class="drag-n-drop icons-list dropdown-simple dropdown-small position-absolute" boundary="viewport">
                                <b-dropdown-item class="icons-list__edit" @click="$refs.editUser.open(user.id, true)">
                                    Edit User
                                </b-dropdown-item>

                                <b-dropdown-item class="icons-list__delete" @click="deleteUser(user.id)" v-if="!user.is_main_admin">
                                    Remove User
                                </b-dropdown-item>

                                <b-dropdown-item v-if="!user.confirmed" class="icons-list__invite" @click="sendActivationEmail(user.id)">
                                    Re-send Invite
                                </b-dropdown-item>
                            </b-dropdown>

                            <div class="image-block image-block--rounded--lg">
                                <Avatar
                                    :username="user.name"
                                    :src="user.avatar"
                                    :size="75"
                                />
                            </div>

                            <h5 class="heading5">{{ user.name }}</h5>
                            <p>{{ user.job_title }}</p>
                        </div>
                    </li>
                </ul>
            </div>

            <header class="main-inner__header">
                <h2 class="heading2">Users</h2>

                <a class="link" @click="addUser(false)">+ Add User</a>
            </header>

            <div class="main-inner__content">
                <ul class="list-contacts list-contacts--full">
                    <li v-for="user in users.common" :key="user.id">
                        <div class="card card--bordered card--centered">

                            <b-dropdown class="drag-n-drop icons-list dropdown-simple dropdown-small position-absolute" boundary="viewport">
                                <b-dropdown-item class="icons-list__edit" @click="$refs.editUser.open(user.id, false)">
                                    Edit User
                                </b-dropdown-item>

                                <b-dropdown-item class="icons-list__delete" @click="deleteUser(user.id)">
                                    Remove User
                                </b-dropdown-item>

                                <b-dropdown-item v-if="!user.confirmed" class="icons-list__invite" @click="sendActivationEmail(user.id)">
                                    Re-send Invite
                                </b-dropdown-item>
                            </b-dropdown>

                            <div class="image-block image-block--rounded--lg">
                                <Avatar
                                    :username="user.name"
                                    :src="user.avatar"
                                    :size="75"
                                />
                            </div>
                            <h5 class="heading5">{{ user.name }}</h5>
                            <p>{{ user.job_title }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <edit-user
            ref="editUser"
            v-on:userSaved="getLists"
        >
        </edit-user>
    </div>
</template>

<script>
import team from "@/services/team.service";
import editUser from "@/components/team/EditUser"
import Avatar from "@/components/ui/Avatar.vue";
import Vue from "vue";

export default {
    components: {
        editUser,
        Avatar,
    },

    data() {
        return {

            users: {
                admins: [],
                common: [],
            },

            search: "",
        }
    },

    mounted() {
        this.getLists()

        this.$store.watch(state => {
            if (state.needUpdateUsers) {
                this.getLists()
            }
        })
    },

    methods: {
        getLists() {
            this.getUsersList()
        },

        getUsersList() {
            team.getUsersIndex(this.search).then(response => {
                this.users = response.data
            })
        },

        addUser(isAdmin) {
            this.$refs.editUser.open(0, isAdmin)
        },

        deleteUser(id) {
            if (confirm("Remove user?")) {
                team.deleteUser(id).then(() => {
                    this.getUsersList()
                })
            }
        },

        sendActivationEmail(id) {
            team.sendActivationEmail(id).then((response) => {

                let notifyText

                if (response.data) {
                    notifyText = "Email sent"
                }
                else {
                    notifyText = "Error occurred while sending message"
                }

                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: notifyText
                })

                this.getLists();
            })
        }
    }
}
</script>

<style scoped>

</style>
