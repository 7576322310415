<template>
    <b-modal id="modal-duplicate-tracker" title="Duplicate Tracker" modal-class="modal-w-md">

        <h6 class="heading6 mb-1">Tracker Name*</h6>
        <b-form-input class="form-control--mid mb-3" v-model="form.name"></b-form-input>

        <template v-if="trackerType === trackerTypes.PRIVATE">
            <div class="input-checkbox">
                <input id="copy-startups" type="checkbox" v-model="form.copy_startups">
                <label for="copy-startups">Include startups in tracker</label>
            </div>

            <div v-if="$store.getters.hasAgencyClients" class="input-checkbox">
                <input id="client" type="checkbox" v-model="showClients">
                <label for="client">Duplicate to client account</label>
            </div>
        </template>

        <ClientSelect
            v-if="showClients"
            v-model="client"
        />

        <template #modal-footer>
            <PrimaryButton
                :disabled="!canSave"
                caption="Create Duplicate Tracker"
                loading-caption="Creating Duplicate..."
                :loading="loading"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<script>
import Vue from "vue";
import trackersService from "@/services/trackers.service";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import {trackerTypes} from "@/constants/trackers";
import ClientSelect from "@/components/agencyClients/ClientSelect.vue";

export default {
    components: {ClientSelect, PrimaryButton},

    data() {
        return {
            id: 0,
            trackerType: null,

            form: {
                name: [],
                copy_startups: false,
            },

            showClients: false,
            client: null,
            loading: false,
        }
    },

    computed: {
        trackerTypes() {
            return trackerTypes
        },

        canSave() {
            return this.form.name.length && (!this.showClients || this.client);
        },
    },

    methods: {
        open(id, name, trackerType) {
            this.id = id;
            this.trackerType = trackerType;
            this.showClients = false
            this.client = null;
            this.form.name = "Duplicate of " + name;
            this.form.copy_startups = false;

            if (this.$store.state.accountType !== 'premium') {
                trackersService.getCount().then(response => {
                    if (response.data >= this.constants.enterpriseLimits.trackers[this.$store.state.accountType]) {
                        this.showNeedUpgrade('trackers');
                    } else {
                        this.$bvModal.show('modal-duplicate-tracker');
                    }
                })
            } else {
                this.$bvModal.show('modal-duplicate-tracker');
            }
        },

        async save() {
            this.loading = true;

            let postData = {...this.form};

            if (this.showClients && this.client) {
                postData.client_id = this.client.id;
            }

            try {
                const {data} = await trackersService.duplicate(this.id, postData);

                if (data) {
                    let messageText = "New tracker created";

                    if (!this.client) {
                        messageText += ": <a class='btn btn-outline-success btn-notify btn-lg ml-2' href='/trackers/" + data + "' target='_blank'>" + this.form.name + "</a>";
                    }

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 10000,
                        text: messageText,
                    });

                    this.$emit('trackerSaved');
                } else {
                    Vue.notify({
                        group: 'app',
                        type: 'error',
                        duration: 10000,
                        text: "Tracker was not created",
                    });
            }} catch(e) {
                console.log(e.message);
            }

            this.loading = false;
            this.$bvModal.hide('modal-duplicate-tracker');
        },
    }
}
</script>

<style scoped>

</style>
