import useFiltersOptions from "@/composables/search/useFiltersOptions";
import {nextTick, reactive, ref, watch} from "vue";
import dateHelper from "@/helpers/date";

const dateFilters = ['lastFundingDate', 'foundedYear', 'lastInteraction'];

export default function () {
    const {filtersOptions, methods: filtersOptionsMethods, methods: {addLocations}} = useFiltersOptions();

    const filters = reactive({
        fundingSeries: [],
        fundingRange: [],
        revenueRange: [],
        employeeCount: [],
        lastFundingDate: null,
        foundedYear: null,
        lastInteraction: null,
        tags: [],
        categories: [],
        locations: [],
        ecosystems: [],
        trackers: [],
        investors: [],
        clients: [],
        clientsCategories: [],
        similarTracker: null,
        ecosystemTypes: [],
        irlScore: [1, 10],
    });

    const customDataFilter = ref([]);
    const notWatchFilters = ref(false);

    dateFilters.forEach(name => {
        watch(() => filters[name], (value) => {
            if (value && value[0] == null && value[1] == null) {
                filters[name] = null;
            }
        });
    });

    function clearFilter(name) {
        if (name.search('custom') >= 0) {
            let parts = name.split('_')
            let index = customDataFilter.value.findIndex(item => item.id === parts[1]);

            if (index >= 0) {
                customDataFilter.value.splice(index, 1)
            }
        }
        else {
            if (Array.isArray(filters[name])) {
                if (name === 'irlScore') {
                    filters[name] = [1, 10];
                } else {
                    filters[name] = [];
                }
            } else {
                filters[name] = null;
            }
        }
    }

    async function populateFilters(rules) {
        notWatchFilters.value = true;
        clearFilters();

        for (const rule of rules) {
            if (rule.condition !== undefined) {
                await populateFilters(rule.rules)
            } else {
                if (!["Keyword"].includes(rule.name)) {
                    if (rule.name.search('custom') >= 0) {
                        addCustomDataFilter(rule);
                    } else {
                        await setFilter(rule.name, rule.value);
                    }
                }
            }
        }

        nextTick(() => notWatchFilters.value = false);
    }

    async function setFilter(name, value) {
        if (value == null) {
            clearFilter(name);
        } else {
            let options = filtersOptions[name];

            if (name === 'clientsCategories') {
                options = filtersOptions['categories']
            }

            if (options !== undefined) {
                if (name === 'locations') {
                    let missingLocations = value.filter(item => !options.some(option => option.value === item));

                    if (missingLocations.length) {
                        await addLocations(missingLocations);
                    }
                }

                let values = options.filter(item => {
                    return value.some(val => {
                        if (typeof val === 'object') {
                            return (!('min' in val && 'min' in item.value) || val.min === item.value.min)
                                && (!('max' in val && 'max' in item.value) || val.max === item.value.max);
                        } else {
                            return val === item.value;
                        }
                    });
                });

                if (values.length) {
                    if (['categories', 'tags'].includes(name)) {
                        filters[name].push(values[0]);
                    } else {
                        filters[name] = values;
                    }
                }
            } else {
                if (dateFilters.includes(name)) {
                    filters[name] = dateHelper.encodeInterval(value);
                } else {
                    filters[name] = value;
                }
            }
        }
    }

    function addCustomDataFilter({name, value, label, valueAlias}) {
        const [, id] = name.split('_');

        customDataFilter.value.push({
            id,
            name,
            label,
            value,
            valueAlias,
        });
    }

    function clearFilters() {
        for (let name in filters) {
            clearFilter(name);
        }

        customDataFilter.value = [];
    }

    function removeFilterFromGroupByValue(name, value) {
        let index = filters[name].findIndex(item => item.value === value);

        if (index >= 0) {
            filters[name].splice(index, 1);
        }
    }

    return {
        filters,
        notWatchFilters,
        filtersOptions,
        customDataFilter,
        clearFilter,
        clearFilters,
        populateFilters,
        removeFilterFromGroupByValue,
        setFilter,
        filtersOptionsMethods,
    }
}
