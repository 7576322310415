<script setup>
import Vue, {ref, onMounted} from "vue";
import {useRoute} from "vue2-helpers/vue-router";
import publicService from "@/services/public.service";
import SimpleSignup from "@/views/common/SimpleSignup.vue";
import {useStore} from "vue2-helpers/vuex";

const route = useRoute();
const store = useStore();
const signup = ref(null);

onMounted(() => {
    checkHash();
});

async function checkHash() {
    const {data} = await publicService.checkEcosystemInviteHash(route.params.hash);

    if (data.status === 'success' && data.message === 'register') {
        signup.value.show();

        if (route.query['show-login']) {
            signup.value.modalAction = 'login';
        }
    } else {
        Vue.notify({
            group: 'app',
            type: data.status,
            duration: 5000,
            text: data.message
        })
    }

    if (store.getters.isEcosystem && !store.getters.isApproved) {
        setTimeout(() => {
            window.location.href = '/';
        }, 3000);
    }
}
</script>

<template>
    <main class="enter-page">
        <header v-if="!store.getters.isLoggedIn" class="header">
            <span class="logo"></span>
        </header>

        <SimpleSignup
            ref="signup"
            company-type="ecosystem"
            :return-url="`${route.fullPath}?show-login=1`"
            :disable-hide="true"
            @loggedIn="checkHash"
        />
    </main>
</template>

<style scoped>

</style>
