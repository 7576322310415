<script setup>
import multiselect from "vue-multiselect";
import {useStore} from "vue2-helpers/vuex";
import {onMounted, ref, watch} from "vue";
import agencyClientsService from "@/services/agency-clients.service";

const emit = defineEmits(['input']);
const store = useStore();
const client = ref(null);
const clients = ref([]);

watch(() => store.getters.agencyClients, (value) => {
    if (!store.state.user.is_demo_account) {
        clients.value = value;
    }
}, {immediate: true});

watch(client, (value) => {
    emit('input', value);
});

onMounted(() => {
    if (!store.state.user.is_demo_account) {
        store.dispatch('fetchAgencyClients');
    }
});

async function searchClients(search) {
    if (store.state.user.is_demo_account) {
        if (search) {
            const {data} = await agencyClientsService.getClients(search);

            clients.value = data;
        } else {
            clients.value = [];
        }
    }
}
</script>

<template>
    <div>
        <multiselect
            class="multiselect-mid"
            v-model="client"
            :options="clients"
            select-label=""
            deselect-label=""
            label="name"
            track-by="name"
            @search-change="searchClients"
        />
    </div>
</template>

<style scoped>

</style>
