import Vue from 'vue'
import Vuex from 'vuex'
import user from "@/services/user.service";
import messageCenter from './store/modules/messageCenter'
import tracker from './store/modules/tracker'
import homepage from './store/modules/homepage'
import dashboard from './store/modules/dashboard'
import radar from './store/modules/radar'
import search from './store/modules/search'
import tags from './store/modules/tags'
import roles from './store/modules/roles'
import clients from './store/modules/clients'
import customFields from './store/modules/customFields'
import agencyClients from './store/modules/agencyClients'
import team from './store/modules/team'
import misc from "@/store/modules/misc";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        messageCenter,
        tracker,
        homepage,
        dashboard,
        radar,
        search,
        tags,
        customFields,
        roles,
        clients,
        agencyClients,
        misc,
        team,
    },
    state: {
        token: localStorage.getItem('token') || '',
        companyType: localStorage.getItem('companyType') || '',
        accountType: localStorage.getItem('accountType') || '',
        maxUsers: localStorage.getItem('max_users') || '',
        realAccountType: localStorage.getItem('realAccountType') || '',
        isAdmin: localStorage.getItem('isAdmin') || false,
        approveStatus: localStorage.getItem('approveStatus') || '',
        returnUrl: "",
        needUpdateTrackers: false,
        needUpdateUsers: false,
        needUpdateStartups: false,
        needUpdateEcosystems: false,
        needUpdateEnterprises: false,
        needUpdateTasks: false,
        needUpdateLatestActivity: false,
        needUpdatePaymentData: false,
        needUpdateUserData: false,
        needLogout: false,
        needAcceptTerms: false,
        addStartup: false,
        addEcosystem: false,
        addEnterprise: false,
        openCompanyProfile: false,
        canLoadGlobalComponents: false,
        showNeedUpgrade: false,
        showUpgrade: false,
        categories: [],

        user : {
            id: 0,
            name: "",
            avatar: "",
            company_type: "",
            company_name: "",
            company_website: "",
            company_url: "",
            company_profile_text: "",
            company_logo: "",
            is_admin: false,
            permissions: [],
            admin_groups: [],
            show_company_profile_form: false,
            show_similar: false,
            settings: {
                hide_no_folders: 1,
                search_big_companies: 0,
                activity_filters: '',
                dashboard: [],
                show_tags_on_add_startup_form: 1,
                disable_external_messaging: 1,
                users_feed_access: 'disabled',
                disable_ai: 1,
                disable_messages: 1,
                disable_tasks: 1,
                disable_add_users: 1,
            },
            impersonated: false,
            profileCompleted: false,
        },

        newMessages: [],

        pageNotFound: false,
    },

    mutations: {
        authSuccess(state, user) {
            state.token = user.token
            localStorage.setItem('token', user.token)
            this.commit('setUserSettings', user)
        },

        authError(state) {
            state.token = ''
            localStorage.removeItem('token');
        },

        updateUserData() {
            user.getCurrentUserData().then(response => {
                this.commit('setUserSettings', response.data)
            })
        },

        setUser(state, user) {
            this.commit('setUserSettings', user)
        },

        setUserSettingsItem(state, settings) {
            state.user[settings.name] = settings.value
        },

        setFeatureSettings(state, settings) {
            state.user.settings[settings.name] = settings.value
        },

        setUserSettings(state, user) {
            state.user = user

            state.companyType = user.company_type
            localStorage.setItem('companyType', user.company_type)

            state.accountType = user.account_type
            localStorage.setItem('accountType', user.account_type)

            state.realAccountType = user.real_account_type
            localStorage.setItem('realAccountType', user.real_account_type)

            state.maxUsers = user.max_users
            localStorage.setItem('maxUsers', user.max_users)

            state.isAdmin = user.is_admin
            localStorage.setItem('isAdmin', user.is_admin)

            if (typeof user.approve_status !== 'undefined') {
                state.approveStatus = user.approve_status
                localStorage.setItem('approveStatus', user.approve_status)
            }
        },

        logout(state) {
            state.user = {}
            state.token = ''
            state.companyType = ''
            localStorage.removeItem('token')
            localStorage.removeItem('companyType')
        },

        setReturnUrl(state, value) {
            state.returnUrl = value
        },

        setNeedUpdateTrackers(state, value) {
            state.needUpdateTrackers = value
        },

        setNeedUpdateUsers(state, value) {
            state.needUpdateUsers = value
        },

        setNeedUpdateStartups(state, value) {
            state.needUpdateStartups = value
        },

        setNeedUpdateEcosystems(state, value) {
            state.needUpdateEcosystems = value
        },

        setNeedUpdateEnterprises(state, value) {
            state.needUpdateEnterprises = value
        },

        setNeedUpdateTasks(state, value) {
            state.needUpdateTasks = value
        },

        setNeedUpdatePaymentData(state, value) {
            state.needUpdatePaymentData = value
        },

        setNeedUpdateUserData(state, value) {
            state.needUpdateUserData = value
        },

        setAddStartup(state, value) {
            state.addStartup = value
        },

        setAddEcosystem(state, value) {
            state.addEcosystem = value
        },

        setAddEnterprise(state, value) {
            state.addEnterprise = value
        },

        setOpenCompanyProfile(state, value) {
            state.openCompanyProfile = value
        },

        setNewMessages(state, value) {
            state.newMessages = value
        },

        setCanLoadGlobalComponents(state, value) {
            state.canLoadGlobalComponents = value
        },

        setCategories(state, value) {
            state.categories = value
        },

        showNeedUpgrade(state, value) {
            state.showNeedUpgrade = value
        },

        showUpgrade(state, value) {
            state.showUpgrade = value
        },

        setNeedLogout(state, value) {
            state.needLogout = value
        },

        setNeedAcceptTerms(state, value) {
            state.needAcceptTerms = value
        },

        setPageNotFound(state, value) {
            state.pageNotFound = value
        },

        setProfileActivityFilters: (state, filters) => {
            state.user.settings.activity_filters = filters
        },

        setNeedUpdateLatestActivity: (state, value) => state.needUpdateLatestActivity = value
    },

    getters : {
        isLoggedIn: state => state.token.length > 0,
        isEnterprise: state => state.companyType === "enterprise",
        isFreeEnterprise: state => state.companyType === "enterprise" && state.accountType === 'free',
        isPremiumEnterprise: state => state.companyType === "enterprise" && state.accountType === 'premium',
        isTestEnterprise: state => state.companyType === "enterprise" && state.realAccountType === 'test',
        isEventLimited: state => state.companyType === "enterprise" && state.accountType === 'event_limited',
        isEcosystem: state => state.companyType === "ecosystem",
        isPremiumEcosystem: state => state.companyType === "ecosystem" && ['premium','trial'].includes(state.accountType),
        isTrialEcosystem: state => state.companyType === "ecosystem" && state.accountType === 'trial',
        isNotEcosystemOrPremium: state => state.companyType !== "ecosystem" || (state.companyType === "ecosystem" && ['premium','trial'].includes(state.accountType)),
        isPremium: state => state.accountType === "premium",

        canExport: state => state.companyType !== "ecosystem" || state.accountType === 'premium',
        isStartup: state => state.companyType === "startup",
        isAdmin: state => state.isAdmin === 'true' || state.isAdmin === true,
        isApproved: state => ['approved','private'].indexOf(state.approveStatus) >= 0,

        canConnectToEnterprises: state => ['basic','premium'].indexOf(state.accountType) >= 0,
        canInviteEnterprises: state => ['basic','premium'].indexOf(state.accountType) >= 0,
        canInviteStartups: state => ['basic','premium'].indexOf(state.accountType) >= 0,

        profileActivityFilters: state => state.user.settings.activity_filters,

        categories: state => state.categories,
        settings: state => state.user.settings,
        permissions: state => state.user.permissions,
        watchEmbargoed: state => state.user.settings.watch_embargoed,
        hasAgencyClients: state => state.user.has_agency_clients || state.user.is_demo_account,
    },
})
