<template>
    <div v-if="$store.state.user.is_admin">
        <div class="topbar topbar--full pt-4">
            <router-link to="/settings" class="link-back">All Settings</router-link>
            <h2 class="heading1 mb-0">Admin</h2>
        </div>
        
        <div class="admin-page">
            <Header></Header>
            <div class="admin-page__content">
            <div class="tab-content p-lg-4">
                
                <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h4 class="heading4 mb-3 mb-sm-0">Tracker Roles & Permissions</h4>
    
                    <b-button class="w-100 w-sm-auto" variant="primary"  @click="$refs.editRole.open(0)" size="lg" v-b-modal.modal-new-folder>+ Add New Role</b-button>
                </div>
                
                <EditRole
                    ref="editRole"
                    v-on:roleSaved="getList">
                </EditRole>
                
                <div class="details-table-wrapper">
                    <table class="details-table details-table--secondary">
                        <thead>
                            <tr>
                                <th></th>
                                <th v-for="role in roles" :key="role.id">
                                    {{role.name}}

                                    <b-dropdown class="icons-list dropdown-single ml-2" boundary="viewport">
                                        <b-dropdown-item class="icons-list__edit" @click="$refs.editRole.open(role.id)">Edit</b-dropdown-item>
                                        <b-dropdown-item class="icons-list__delete" v-if="role.can_delete" @click="deleteRole(role.id)">
                                            Delete
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(name, permission) in permissionsList" :key="permission">
                                <td>
                                    {{name}}
                                </td>
                                <td v-for="role in roles" :key="role.id">
                                    <div class="input-checkbox">
                                        <input type="checkbox" @change="togglePermission(role.id, permission)" :checked="role.permissions.indexOf(permission) >= 0" :id="'perm-' + permission + '-role-' + role.id"/>
                                        <label :for="'perm-' + permission + '-role-' + role.id"></label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/settings/admin/AdminHeader";
import permissions from "@/services/permissions.service"
import EditRole from "@/components/permissions/EditRole"

export default {
    components: {
        Header,
        EditRole
    },

    data() {
        return {
            roles: [],
            permissionsList: {},
            currentRoleId: 0,
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            permissions.getTrackerPermissionsList().then(result => {
                this.permissionsList = result.data
            })

            permissions.getRolesWithPermissions().then(result => {
                this.roles = result.data
            })
        },

        togglePermission(roleID, permission) {
            permissions.togglePermission(roleID, permission)
        },

        deleteRole(roleID) {
            if (confirm("Delete role?")) {
                permissions.deleteRole(roleID).then(() => {
                    this.getList()
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
