<template>
    <b-modal :id="'modal-edit-review-' + prefix" modal-class="modal-w-md modal-rating" no-enforce-focus @hidden="$emit('closed')">
        <template #modal-header>
            <h5 v-if="review.isNew" class="modal-title">Ratings</h5>
            <h5 v-else class="modal-title">Edit Review</h5>
            <a v-if="!review.isNew" class="link" @click="deleteReview">Delete Review</a>
            <button @click="close()" class="close"></button>
        </template>
        
        <div class="rating-section mb-2">
            <h5 class="heading5 heading5--secondary">Your rating:</h5>
            <div class="rating rating-modal">
                <span :class="{'rating__current': review.interestLevel >= 1}" @click="review.interestLevel = 1"></span>
                <span :class="{'rating__current': review.interestLevel >= 2}" @click="review.interestLevel = 2"></span>
                <span :class="{'rating__current': review.interestLevel >= 3}" @click="review.interestLevel = 3"></span>
                <span :class="{'rating__current': review.interestLevel >= 4}" @click="review.interestLevel = 4"></span>
                <span :class="{'rating__current': review.interestLevel >= 5}" @click="review.interestLevel = 5"></span>
            </div>
        </div>

        <template v-if="!$store.getters.settings.disable_review_message">
            <span v-if="review.messageError.length" class="error">{{review.messageError}}</span>
            <textarea class="form-control--mid form-control" v-model="review.message"/>
        </template>

        <div v-for="(field, index) in review.reviewerFields" :key="index" class="mt-2 mb-4">
            <h5 class="heading5 heading5--secondary">{{field.field.name}}</h5>

            <span v-if="field.error.length" class="error">{{field.error}}</span>

            <CustomFieldValue
                :field="review.reviewerFields[index].field"
                :init-value="review.reviewerFields[index].value"
                :instantUpdate="false"
                @valueChanged="(value) => reviewerValueChanged(index, value)"
            />
        </div>

        <template #modal-footer>
            <PrimaryButton
                :disabled="review.interestLevel === 0"
                caption="Save"
                :loading="loading"
                loading-caption="Saving..."
                @click="save"
            />
        </template>

    </b-modal>
</template>

<script>
import trackersReviews from "@/services/trackers-reviews.service";
import CustomFieldValue from "@/components/customFields/CustomFieldValue";
import {validateNumericValues} from "@/helpers/functions";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";

export default {
    components: {
        PrimaryButton,
        CustomFieldValue
    },

    props: {
        prefix: String
    },

    data() {
        return {
            trackerId: 0,
            companyId: 0,

            review: {
                message: '',
                messageError: '',
                interestLevel: 0,
                isNew: true,
                reviewerFields: [],
            },

            fieldFilesNumber: 0,
            loading: false,
        }
    },

    methods: {
        init() {
            this.review.message = ""
            this.review.messageError = ""
            this.review.interestLevel = 0
            this.review.reviewerFields = []

            trackersReviews.getEdit(this.trackerId, this.companyId).then(response => {
                this.review.message = response.data.message
                this.review.interestLevel = response.data.interest_level
                this.review.isNew = response.data.is_new
                this.review.reviewerFields = response.data.reviewer_fields.map(item => ({...item, error:''}));
            })
        },

        open(trackerId, companyId) {
            this.trackerId = trackerId
            this.companyId = companyId

            this.init()
            this.$bvModal.show('modal-edit-review-' + this.prefix)
        },

        close() {
            this.$bvModal.hide('modal-edit-review-' + this.prefix)
        },

        reviewerValueChanged(index, value) {
            this.review.reviewerFields[index].value = value
        },

        save() {
            let hasErrors = false
            this.review.messageError = ''

            if (!this.review.message.length && !this.$store.state.user.settings.allow_empty_review_comments) {
                this.review.messageError = 'This field is mandatory'
                hasErrors = true
            }

            for (let i in this.review.reviewerFields) {

                this.review.reviewerFields[i].error = ''

                if (this.review.reviewerFields[i].required) {
                    if (!this.stringHelper.fieldFilled(this.review.reviewerFields[i].value)) {
                        this.review.reviewerFields[i].error = 'This field is mandatory'
                        hasErrors = true
                    }
                }

                let [numberErrors, hasNumberErrors] = validateNumericValues(this.review.reviewerFields);

                if (hasNumberErrors) {
                    for (let i in numberErrors) {
                        this.review.reviewerFields[i].error = numberErrors[i]
                    }
                    hasErrors = true
                }
            }

            if (!hasErrors) {
                this.loading = true;

                trackersReviews.save(this.trackerId, this.companyId, this.review).then(() => {
                    this.loading = false;
                    this.fieldFilesNumber = this.fileHelper.fieldsWithNewFilesNumber(this.review.reviewerFields)

                    if (this.fieldFilesNumber) {
                        this.review.reviewerFields.forEach(field => {

                            let formData = new FormData();
                            let hasFiles = false

                            field.value.files.forEach(file => {
                                if (file.id == 0) {
                                    formData.append('file[]', file)
                                    hasFiles = true
                                }
                            })

                            if (hasFiles) {
                                trackersReviews.saveFiles(this.trackerId, this.companyId, field.field.id, formData).then(() => {
                                    this.fieldFilesSaved()
                                })
                            }
                        })
                    } else {
                        this.$emit('reviewSaved', this.companyId)
                        this.$bvModal.hide('modal-edit-review-' + this.prefix)
                    }
                })
            }
        },

        deleteReview() {
            if (confirm('Delete Review?')) {
                trackersReviews.delete(this.trackerId, this.companyId).then(() => {
                    this.$emit('reviewSaved', this.companyId)
                    this.$bvModal.hide('modal-edit-review-' + this.prefix)
                })
            }
        },

        fieldFilesSaved() {
            --this.fieldFilesNumber

            if (!this.fieldFilesNumber) {
                this.$emit('reviewSaved', this.companyId)
                this.$bvModal.hide('modal-edit-review-' + this.prefix)
            }
        },
    }
}
</script>

<style scoped>

</style>
