import { render, staticRenderFns } from "./ClientSelect.vue?vue&type=template&id=194db212&scoped=true&"
import script from "./ClientSelect.vue?vue&type=script&setup=true&lang=js&"
export * from "./ClientSelect.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "194db212",
  null
  
)

export default component.exports