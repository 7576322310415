<template>
    <!-- I will see how you implement this page and after will spread the gray background -->
    <div>
        <div v-if="errorMessage.length">
            {{ errorMessage }}
        </div>

        <div v-if="displayType !== 'list' && loading" class="blocked-page">
            <div class="spinner-border text-primary" role="status"></div>
        </div>

        <div v-if="loaded">
            <div :id="displayType" class="">

                <div class="tracker-section">
                    <h2 class="tracker-name heading2">
                        <template v-if="!nameEditMode">
                            {{ info.name }}
                        </template>

                        <template v-else>
                            <input v-model="newName" v-on:keyup.enter="updateName" class="form-control">
                        </template>

                        <template v-if="canEditInfo">
                            <a v-if="!nameEditMode" @click="newName = info.name; nameEditMode = true"
                               class="tracker-name__edit"></a>
                            <template v-else>
                                <div class="tracker-name__actions">
                                    <a @click="updateName" class="tracker-name__actions__save"></a>
                                    <a @click="nameEditMode = false" class="tracker-name__actions__cancel"></a>
                                </div>
                            </template>
                        </template>
                    </h2>

                    <b-form-checkbox v-if="hasPipeline"
                                     v-model="displayType"
                                     name="check-button"
                                     value="list"
                                     unchecked-value="pipeline" switch>
                    </b-form-checkbox>

                    <b-form-checkbox v-if="info.type === trackerTypes.POC"
                                     class="poc-switcher"
                                     v-model="displayType"
                                     name="check-button"
                                     value="list"
                                     unchecked-value="timeline" switch>
                    </b-form-checkbox>

                    <a v-if="$store.getters.isEnterprise && info.notifications" @click="showNotificationSettings" class="notifications-settings" :class="{'notifications-settings--enabled': notificationsEnabled}">
                        Notifications
                    </a>

                    <!--<a href="#" class="info-plate">Info & Manage</a>-->

                    <div class="info-plate info-plate--updated"
                         :class="showInfoPanel ? 'info-plate--open' : 'info-plate--not-open'">
                        <div class="info-plate__link" v-b-toggle.collapse-1 variant="primary">
                            Info & Manage

                            <b-dropdown v-if="info.can_edit" id="dropdown-1" text="Actions" class="icons-list info-plate__actions" variant="link" no-caret>
                                <b-dropdown-item @click="$refs.editTracker.open(id)" class="icons-list__edit">
                                    Edit tracker
                                </b-dropdown-item>
                                <b-dropdown-item @click="$refs.duplicateTracker.open(id, info.name, info.type)" class="icons-list__copy">
                                    Duplicate tracker
                                </b-dropdown-item>
                                <b-dropdown-item v-if="ownerEnterprise && !$store.getters.settings.disable_tasks" @click="$refs.editTask.open(0)" class="icons-list__task">
                                    Add task
                                </b-dropdown-item>
                                <b-dropdown-item v-if="canEditInfo" @click="deleteTracker" class="icons-list__delete">
                                    Delete tracker
                                </b-dropdown-item>
                                <b-dropdown-item v-if="canEditInfo && info.status !== 'archive'" @click="archiveTracker" class="icons-list__archive">
                                    Archive
                                </b-dropdown-item>
                                <b-dropdown-item v-if="canEditInfo && info.status === 'archive'" @click="unarchiveTracker" class="icons-list__archive">
                                    Unarchive
                                </b-dropdown-item>
                                <b-dropdown-item v-if="canEditInfo" @click="$refs.selectFolder.open()" class="icons-list__add-folder">
                                    Add to Folder
                                </b-dropdown-item>
                            </b-dropdown>

                            <!--<a href="#" class="info-plate__actions">Actions</a>-->
                        </div>

                        <b-collapse id="collapse-1" :visible="initCompaniesCount === 0 || showActivityTab"
                                    class="info-plate__content" v-model="showInfoPanel">

                            <b-card no-body>
                                <b-tabs justified>
                                    <b-tab title="Overview" active>
                                        <h5 class="heading5 heading5--secondary d-flex justify-content-between align-items-center mb-3">
                                            <span>{{ info.name }}</span>

                                            <select :class="{'status-select--live': newStatus === 'live'}"
                                                    v-if="info.type === 'public' && info.user_permissions.indexOf('tracker-change-status') >= 0"
                                                    class="status-select" v-model="newStatus">
                                                <option :class="name" v-for="(name, key) in constants.trackerStatus"
                                                        :key="key" :value="key">
                                                    {{ name }}
                                                </option>
                                            </select>
                                        </h5>

<!-- New dropdown  -->
                                        <div v-if="[constants.companyPfizer, constants.companyTravelers, constants.companyColgate, constants.companyAnton].includes($store.state.user.company_id)" class="d-flex justify-content-between">
                                            <b-dropdown v-if="info.type === 'public' && newStatus === 'live'" id="d" text="Copy Tracker Links" class="copy-tracker-links">
                                                <b-dropdown-item @click="copyInternalLink">Copy <b>Internal</b> Tracker Link</b-dropdown-item>
                                                <b-dropdown-item @click="copyPublicLink">Copy <b><template v-if="info.only_referral">Referral</template><template v-else>Public</template></b> Challenge Link</b-dropdown-item>
                                            </b-dropdown>

                                            <a v-else @click="copyInternalLink" class="copy-link">Copy internal tracker link</a>

                                            <router-link v-if="info.type === 'public'" class="preview-link" :to="'/trackers/' + id + '/preview'">Preview</router-link>
                                        </div>
<!-- End New dropdown -->

                                        <template v-else-if="info.type === 'public'">
                                            <a v-if="newStatus === 'live'" class="copy-link" @click="copyPublicLink">
                                                Copy <template v-if="info.only_referral">Referral</template><template v-else>Public</template> Challenge Link
                                            </a>
                                            <router-link class="preview-link ml-1" :to="'/trackers/' + id + '/preview'">Preview</router-link>
                                        </template>

                                        <p><strong>Created:</strong> {{ info.created }}</p>

                                        <template v-if="info.type === 'public' || info.status !== 'draft'">
                                            <p><strong>Status:</strong> {{ constants.trackerStatus[info.status] }}</p>
                                        </template>


                                        <template v-if="info.description">
                                            <p><strong>Description</strong></p>
                                            <div v-html="info.description" class="info-plate__description"></div>
                                        </template>

                                        <template v-if="info.categories.length">
                                            <p><strong>Categories:</strong></p>
                                            <p>{{ info.categories.join(', ') }}</p>
                                        </template>

                                        <template v-if="info.start_date">
                                            <p><strong>Start Date:</strong> {{ info.start_date }}</p>
                                        </template>

                                        <template v-if="info.end_date">
                                            <p><strong>End Date:</strong> {{ info.end_date }}</p>
                                        </template>

                                        <template v-if="info.submitter_fields.length">
                                            <h5 class="heading5 heading5--secondary mt-4 mb-3">Details</h5>

                                            <div v-for="(field, index) in info.submitter_fields" :key="index"
                                                 class="mb-4">
                                                <p><strong>{{ field.field.name }}:</strong></p>

                                                <CustomFieldValue
                                                    :field="info.submitter_fields[index].field"
                                                    :init-value="info.submitter_fields[index].value"
                                                    :editable="false"
                                                />
                                            </div>
                                        </template>

                                        <template v-if="info.type === 'private' && ownerEnterprise">
                                            <h5 class="heading5 heading5--secondary mt-4 mb-2">Ecosystem Sharing</h5>
                                            <div class="shared-ecosystem-label">
                                                <p class="modal-text"> {{ stringHelper.trackerAccess(info) }} <a
                                                    v-if="info.user_permissions.indexOf('tracker-add-ecosystem') >= 0"
                                                    class="heading-edit" @click="changeVisibility"></a></p>
                                            </div>

                                            <AccessSettings
                                                v-if="isEnterprise && info.user_permissions.indexOf('tracker-add-ecosystem') >= 0"
                                                ref="accessSettings"
                                                :from-tracker-page="true"
                                                :init-access-type="info.access_type"
                                                :init-ecosystems="info.ecosystems"
                                                v-on:saved="accessSettingsChanged"
                                            />

                                        </template>

                                        <template v-if="sharedEcosystems.length && false">
                                            <h5 class="heading5 heading5--secondary">Shared Ecosystems</h5>

                                            <ul class="rows-list mb-3">

                                                <li v-for="(ecosystem, index) in sharedEcosystems" :key="index"
                                                    class="">
                                                    <div class="image-block">
                                                        <img :src="LogoHelper.getLogo(ecosystem.logo)" alt="">
                                                    </div>

                                                    <div class="row-list__inner">
                                                        <h5 class="heading5 heading5--secondary">{{
                                                                ecosystem.name
                                                            }}</h5>
                                                        <p>Added {{ ecosystem.date }} by {{ ecosystem.user }}</p>
                                                    </div>

                                                    <a v-if="info.user_permissions.indexOf('tracker-add-ecosystem') >= 0"
                                                       @click="revokeShareEcosystem(ecosystem.id)"
                                                       class="link-remove link" href="#">Remove</a>
                                                </li>
                                            </ul>
                                        </template>

                                        <div v-if="!$store.getters.settings.disable_ai && $store.getters.isPremiumEnterprise" class="text-center">
                                            <a v-if="info.type === 'private'" @click="showSuggestedStartupsManual" class="btn btn-bordered">
                                                <template v-if="!suggestedStartupsLoading">View Potential Matches</template>
                                                <template v-else>Loading...</template>
                                            </a>
                                        </div>

                                    </b-tab>

                                    <b-tab v-if="$store.getters.isEnterprise" title="Updates" :active="showActivityTab">
                                        <template v-if="!$store.getters.settings.disable_tasks">
                                            <div class="tasks-wrapper" v-if="$store.state.accountType === 'premium'">
                                                <h4 class="heading4 d-flex">Tasks Assigned to
                                                    <div class="tasks__select"
                                                         v-click-outside="() => openTasksTypeSelect = false">
                                                        <div @click="openTasksTypeSelect = true"
                                                             class="tasks__select__chosen">
                                                            <span>{{ taskListTypes[tasksListType] }}</span>
                                                        </div>

                                                        <ul v-show="openTasksTypeSelect">
                                                            <li v-for="(name, value) in taskListTypes" :key="value"
                                                                @click="tasksListType = value; openTasksTypeSelect = false">
                                                                {{ name }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </h4>

                                                <ul class="tasks-list">
                                                    <li v-for="(task, index) in tasks" :key="index">

                                                        <div class="tasks-list__inner">
                                                            <div>{{ task.title }}</div>
                                                            <b-dropdown v-if="task.can_edit"
                                                                        class="ml-5 drag-n-drop icons-list dropdown-simple dropdown-small"
                                                                        boundary="viewport">
                                                                <b-dropdown-item class="icons-list__edit"
                                                                                 @click="editTask(task.id)">
                                                                    Edit Task
                                                                </b-dropdown-item>
                                                                <b-dropdown-item class="icons-list__delete"
                                                                                 @click="deleteTask(task.id)">
                                                                    Remove Task
                                                                </b-dropdown-item>
                                                            </b-dropdown>
                                                        </div>

                                                        <div class="tasks-list__inner">
                                                            <div class="tasks-list__assignee" v-html="task.assignee.name"></div>
                                                            <time class="tasks-list__date" v-html="task.due"></time>

                                                            <TaskStatus
                                                                :read-only="!task.can_edit"
                                                                :rowId="parseInt(task.id)"
                                                                :columnData="task.status"
                                                                v-on:action="changeTaskStatus"
                                                            />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <Pager
                                                v-if="tasks.length"
                                                :pages-count="tasksPagesCount"
                                                :init-page="tasksCurrentPage"
                                                @changePage="changeTasksPage"
                                            />
                                        </template>

                                        <div class="comments">
                                            <div class="comment-form">
                                                <div class="comments__avatar">
                                                    <Avatar
                                                        :username="$store.state.user.name"
                                                        :src="$store.state.user.avatar"
                                                        :size="30"
                                                    />
                                                </div>
                                                <form action="">
                                                    <Mentionable
                                                        :keys="['@']"
                                                        :items="usersMentionList"
                                                        offset="6"
                                                        insert-space
                                                        placement="top-start"
                                                        @apply="applyMention"
                                                    >
                                                        <template #item-@="{ item }">
                                                            <div class="user">
                                                                <Avatar
                                                                    :username="item.name"
                                                                    :src="item.avatar"
                                                                    :size="25"
                                                                />

                                                                <div class="mention-item__inner">
                                                                    {{ item.value }}
                                                                    <span class="dim">
                                                                    ({{ item.name }})
                                                                </span>
                                                                </div>
                                                            </div>
                                                        </template>

                                                        <textarea v-model="newNote" class="comment-form__text"
                                                                  @keyup.enter="addNote"></textarea>
                                                    </Mentionable>
                                                    
                                                    <div class="d-flex justify-content-between">
                                                        <div class="text-mid">
                                                            {{ selectedRecipients.length }}
                                                            {{ stringHelper.getNoun('user', selectedRecipients.length) }}
                                                            will be notified <a
                                                            @click="$bvModal.show('modal-recipients-list')"> <span
                                                            class="heading-edit"></span></a>
                                                        </div>
                                                        <button v-if="newNote.length" @click="addNote" class="button--simple">Post</button>
                                                    </div>
                                                </form>
                                            </div>

                                            <ul class="comments__item-wrapper">
                                                <li class="comments__item" v-for="(note, index) in notes" :key="index">
                                                    <header class="comments__item__header">
                                                        <div class="comments__avatar">
                                                            <Avatar
                                                                :username="note.user_name"
                                                                :src="note.user_avatar"
                                                                :size="30"
                                                            />
                                                        </div>
                                                        <div class="comments__author"
                                                             :class="{deleted: note.user_deleted}">{{
                                                                note.user_name
                                                            }}<span v-if="note.user_deleted"> (D)</span></div>
                                                        <time class="comments__date">
                                                            {{ dateHelper.decodeDate(note.created_at) }}
                                                        </time>
                                                    </header>

                                                    <div class="comments__item__content" v-html="note.text"/>
                                                </li>
                                            </ul>
                                        </div>

                                        <b-modal id="modal-recipients-list" title="Select Team Members To Notify"
                                                 hide-footer modal-class="modal-w-md">

                                            <p>Selected team members will be notified of your update.</p>

                                            <ul class="rows-list mb-3 pl-0">
                                                <li v-if="messageRecipientsList.length > 1">
                                                    <div class="input-checkbox">
                                                        <input id="input-checkbox__all_recipients" type="checkbox"
                                                               v-model="checkAllRecipients">
                                                        <label for="input-checkbox__all_recipients"></label>
                                                    </div>

                                                    <h5 class="heading5 heading5--secondary"><label
                                                        for="input-checkbox__all">Select / Unselect all
                                                        {{ messageRecipientsList.length }} users</label></h5>
                                                </li>

                                                <li v-for="(user, index) in messageRecipientsList" :key="index">

                                                    <div class="input-checkbox">
                                                        <input :id="'input-checkbox__' + index" type="checkbox"
                                                               v-model="messageRecipientsList[index].checked">
                                                        <label :for="'input-checkbox__' + index"></label>
                                                    </div>

                                                    <div class="image-block image-block--rounded">
                                                        <Avatar
                                                            :username="user.name"
                                                            :src="user.avatarUrl"
                                                            :size="30"
                                                        />
                                                    </div>

                                                    <h5 class="heading5 heading5--secondary"><label
                                                        :for="'input-checkbox__' + index">{{ user.name }}</label></h5>
                                                </li>
                                            </ul>
                                            <footer class="modal-footer">
                                                <div class="text-center">
                                                    <button class="button"
                                                            @click="$bvModal.hide('modal-recipients-list')">Close
                                                    </button>
                                                </div>

                                            </footer>
                                        </b-modal>
                                    </b-tab>

                                    <b-tab title="Filter">
                                        <h5 class="heading5 heading5--secondary">
                                            {{ info.type === 'ecosystems' ? 'Ecosystem' : 'Startup' }} name, Description,
                                            Keywords</h5>
                                        <div class="form-search"><input
                                            placeholder="Search for company name, description or keywords" type="text"
                                            v-model="filters.search"></div>

                                        <h5 class="heading5 heading5--secondary mt-3">Filter by rating</h5>

                                        <div class="form-control-wrapper form-control-wrapper--wrap">
                                            <div class="input-radio">
                                                <input id="q1" type="radio" name="ratingCondition" class="mr-1"
                                                       :checked="filters.ratingFilterState === 'all'"
                                                       @click="filters.ratingFilterState = 'all'; filters.ratingCondition =''">
                                                <label for="q1" class="mr-2">Show all</label>
                                            </div>

                                            <div class="input-radio">
                                                <input id="q2" type="radio" name="ratingCondition" class="mr-1"
                                                       :checked="filters.ratingFilterState === 'rated'"
                                                       @click="filters.ratingFilterState = 'rated'">
                                                <label for="q2" class="mr-2">Show rated</label>
                                            </div>

                                            <div class="input-radio">
                                                <input id="q3" type="radio" name="ratingCondition" class="mr-1"
                                                       :checked="filters.ratingFilterState === 'unrated'"
                                                       @click="filters.ratingFilterState = 'unrated'; filters.ratingCondition =''">
                                                <label for="q3" class="mr-2">Show unrated</label>
                                            </div>
                                        </div>

                                        <template v-if="filters.ratingFilterState === 'rated'">
                                            <h6 class="mt-2 mb-0">Rating</h6>
                                            <div class="rating-section mb-3">

                                                <div class="rating">
                                                    <span :class="{'rating__current': filters.rating >= 1}"
                                                          @click="filters.rating = 1"></span>
                                                    <span :class="{'rating__current': filters.rating >= 2}"
                                                          @click="filters.rating = 2"></span>
                                                    <span :class="{'rating__current': filters.rating >= 3}"
                                                          @click="filters.rating = 3"></span>
                                                    <span :class="{'rating__current': filters.rating >= 4}"
                                                          @click="filters.rating = 4"></span>
                                                    <span :class="{'rating__current': filters.rating >= 5}"
                                                          @click="filters.rating = 5"></span>
                                                </div>
                                                <div class="tags-list tags-list--light">
                                                    <b-button
                                                        class="tags-list__tag ml-2"
                                                        :class="{'selected': filters.ratingCondition === 'higher'}"
                                                        :checked="filters.ratingCondition === 'higher'"
                                                        @click="filters.ratingCondition = 'higher'"
                                                    >+ Higer
                                                    </b-button>

                                                    <b-button
                                                        class="tags-list__tag ml-2"
                                                        :class="{'selected': filters.ratingCondition === 'lower'}"
                                                        :checked="filters.ratingCondition === 'lower'"
                                                        @click="filters.ratingCondition = 'lower'"
                                                    >+ Lower
                                                    </b-button>
                                                </div>
                                            </div>
                                        </template>

                                        <template v-if="ownerEnterprise && info.type !== 'ecosystems'">
                                            <h5 class="heading5 heading5--secondary mt-3">Tags</h5>
                                            <multiselect
                                                v-model="filters.tags"
                                                :multiple="true"
                                                placeholder="Select tags"
                                                :options="tagsList"
                                                select-label=""
                                                deselect-label=""
                                                label="name"
                                                track-by="name"
                                                class="multiselect-mid pl-2"
                                            >
                                            </multiselect>
                                        </template>


                                        <h5 class="heading5 heading5--secondary mt-3">Categories</h5>
                                        <multiselect
                                            v-model="filters.categories"
                                            :multiple="true"
                                            placeholder="Select categories"
                                            :options="$store.state.categories"
                                            select-label=""
                                            deselect-label=""
                                            label="name"
                                            track-by="name"
                                            class="multiselect-mid pl-2"
                                        >
                                        </multiselect>

                                        <h5 class="heading5 heading5--secondary mt-3">Trackers</h5>
                                        <multiselect
                                            v-model="filters.trackers"
                                            :multiple="true"
                                            placeholder="Select trackers"
                                            :options="trackersList"
                                            select-label=""
                                            deselect-label=""
                                            label="name"
                                            track-by="name"
                                            class="multiselect-mid pl-2"
                                        >
                                        </multiselect>

                                        <template v-if="info.type !== 'ecosystems'">
                                            <h5 class="heading5 heading5--secondary mt-3">Startup Diligence Fields</h5>
                                            <a class="filters__list__link filters__custom filters__list__link--modal"
                                               @click="openTrackerCustomDataFilter">Select Diligence Fields</a>
                                            <div class="tags-simple-list" v-if="filters.trackerFields.length">
                                                <div class="tags-simple-list__item"
                                                     v-for="(field, index) in filters.trackerFields" :key="index">
                                                    {{ trackerFieldsList[field.id].name }}: {{ field.valueAlias }}
                                                    <a @click="filters.trackerFields.splice(index, 1); $refs.trackerCustomDataFilter.removeFilter(index)"><span
                                                        class="tags-simple-list__item__close"></span></a>
                                                </div>
                                            </div>

                                            <h5 class="heading5 heading5--secondary mt-3">Startup Profile Fields</h5>
                                            <a class="filters__list__link filters__custom filters__list__link--modal"
                                               @click="openProfileCustomDataFilter">Select Profile Fields</a>
                                            <div class="tags-simple-list" v-if="filters.profileFields.length">
                                                <div class="tags-simple-list__item"
                                                     v-for="(field, index) in filters.profileFields" :key="index">
                                                    {{ profileFieldsList[field.id].name }}: {{ field.valueAlias }}
                                                    <a @click="filters.profileFields.splice(index, 1); $refs.profileCustomDataFilter.removeFilter(index)"><span
                                                        class="tags-simple-list__item__close"></span></a>
                                                </div>
                                            </div>

                                            <template v-if="ownerEnterprise">
                                                <h5 class="heading5 heading5--secondary mt-3">Ecosystems</h5>
                                                <multiselect
                                                    v-model="filters.ecosystems"
                                                    :multiple="true"
                                                    placeholder="Select ecosystems"
                                                    :options="ecosystemsList"
                                                    select-label=""
                                                    deselect-label=""
                                                    label="name"
                                                    track-by="name"
                                                    @search-change="updateEcosystemsList"
                                                    class="multiselect-mid pl-2"
                                                >
                                                </multiselect>
                                            </template>

                                            <template v-if="hasPipeline">
                                                <h5 class="heading5 heading5--secondary mt-3">Stage</h5>
                                                <multiselect
                                                    v-model="filters.stages"
                                                    :multiple="true"
                                                    placeholder="Select stage"
                                                    :options="stagesList"
                                                    select-label=""
                                                    deselect-label=""
                                                    label="name"
                                                    track-by="name"
                                                    class="multiselect-mid pl-2"
                                                >
                                                </multiselect>
                                            </template>
                                        </template>

                                        <template v-if="info.type !== 'public'">
                                            <h5 class="heading5 heading5--secondary mt-3">Curator</h5>

                                            <div class="input-checkbox--gray">
                                                <div class="input-checkbox">
                                                    <input type="checkbox" id="added-by-me" v-model="filters.addedByMe">
                                                    <label for="added-by-me"></label>
                                                </div>
                                                <label for="added-by-me">Show only
                                                    {{ info.type === 'ecosystems' ? 'ecosystems' : 'startups' }} added
                                                    by me</label>
                                            </div>
                                        </template>

                                        <div v-if="ownerEnterprise && info.type === 'private'"
                                             class="input-checkbox--gray">
                                            <div class="input-checkbox">
                                                <input type="checkbox" id="added-by-ecosystems"
                                                       v-model="filters.addedByEcosystems">
                                                <label for="added-by-ecosystems"></label>
                                            </div>
                                            <label for="added-by-ecosystems">Added by ecosystems</label>
                                        </div>


                                    </b-tab>

                                </b-tabs>
                            </b-card>

                        </b-collapse>
                    </div>
                </div>

                <div class="tracker-list__actions">
                    <div>
                        <template v-if="displayType === 'list' && companiesCount">
                            Show
                            <select v-model="pageLength" class="select-transparent">
                                <option value="1">1</option>
                                <option value="20">20</option>
                                <option value="50" v-if="companiesCount > 20">50</option>
                                <option value="100" v-if="companiesCount > 50">100</option>
                            </select>
                            entries of {{ companiesCount }}
                        </template>
                    </div>

                    <ul class="actions-list">
                        <template v-if="selectedRows.length">
                            <li class="actions-list__remove"
                                v-if="info.type !== 'public' && selectedRows.length && info.user_permissions.indexOf('tracker-startups-delete') >= 0">
                                <a @click="removeCompanies">
                                    <font-awesome-icon :icon="['fa', 'trash']"/>
                                    Remove From Tracker</a></li>
                        </template>

                        <template v-else>
                            <li class="actions-list__note" v-if="companiesCount > 0 && info.type !== 'ecosystems' && !$store.getters.settings.disable_ai && $store.getters.isTestEnterprise">
                                <div v-if="intelligenceReportLoading">
                                    Loading report...
                                </div>

                                <a v-else href="#" @click.prevent="getIntelligenceReport">Tracker Intelligence Report</a>
                            </li>

                            <li v-if="info.type === 'private' && companiesCount > 0 && info.type !== 'ecosystems' && info.user_permissions.includes('tracker-startups-add') " class="actions-list__similar">
                                <a href="#" @click.prevent="$refs.similarStartups.open(id, true)">Similar Startups</a>
                            </li>

                            <li class="actions-list__add"
                                v-if="(info.type === trackerTypes.PRIVATE || info.type === trackerTypes.POC) && (info.user_permissions.includes('tracker-startups-add') || info.user_permissions.includes('tracker-add-ecosystem'))">
                                <b-dropdown v-if="isEnterprise" text="Add To Tracker" class="dropdown-small">
                                    <b-dropdown-item class="actions-list__add pt-2"
                                                     v-if="info.user_permissions.includes('tracker-startups-add')"
                                                     @click="() => {$refs.addStartups.open()}">Add Startups
                                    </b-dropdown-item>
                                    <b-dropdown-item class="actions-list__ecosystem pt-2 pb-1"
                                                     v-if="info.type === trackerTypes.PRIVATE && info.user_permissions.includes('tracker-add-ecosystem')"
                                                     @click="share">Invite Ecosystems
                                    </b-dropdown-item>
                                </b-dropdown>

                                <a v-else @click="() => {$refs.addStartups.open()}">Add Startups</a>
                            </li>

                            <li class="actions-list__add"
                                v-else-if="info.type === 'ecosystems' && arrayHelper.inArray(info.user_permissions, 'tracker-startups-add')">
                                <a @click="$refs.addEcosystems.open()">Add Ecosystems</a>
                            </li>
                            <!--<li class="actions-list__add" v-if="info.type === 'private' && info.user_permissions.indexOf('tracker-startups-add') >= 0"><a @click="$refs.addStartups.open()">Add Startups</a></li>-->
                            <!--<li class="actions-list__ecosystem" v-if="info.type === 'private' && info.user_permissions.indexOf('tracker-add-ecosystem') >= 0"><a @click="share">Invite Ecosystems</a></li>-->
                            <li v-if="displayType === 'list'" class="actions-list__edit"><a @click="openManageColumns">Edit
                                Columns</a></li>
                        </template>

                        <li class="actions-list__export">
                            <b-dropdown
                                v-if="companiesCount" text="Export"
                                class="topbar__side__actions dropdown-small"
                            >
                                <b-dropdown-item class="icons-list__csv pt-2" @click="exportCSV">
                                    Export CSV
                                </b-dropdown-item>

                                <b-dropdown-item
                                    v-if="info.type !== 'ecosystems' && (selectedRows.length && (!selectedAllResults || companiesCount < 125))"
                                    class="icons-list__pdf pt-2"
                                    @click="openExportPdf"
                                >
                                    Export PDF
                                </b-dropdown-item>

                                <template v-if="info.type !== 'ecosystems' && (selectedRows.length && (!selectedAllResults || companiesCount <= 400))">
                                    <b-dropdown-item
                                        class="icons-list__ppt pt-2" @click="exportPPT(false)">
                                        Export PPT
                                    </b-dropdown-item>

                                    <b-dropdown-item
                                        v-if="$store.state.user.settings.allow_advanced_ppt_export"
                                        class="icons-list__ppt pt-2"
                                        @click="exportPPT(true)"
                                    >
                                        Export PPT (Advanced)
                                    </b-dropdown-item>
                                </template>

                                <b-dropdown-item
                                    v-if="canExportContacts"
                                    class="icons-list__csv pt-2" @click="exportContacts"
                                >
                                    Export Contacts
                                </b-dropdown-item>

                                <b-dropdown-item
                                    v-if="selectedRows.length && info.type !== 'ecosystems'"
                                    class="icons-list__csv pt-2"
                                    @click="exportFundings"
                                >
                                    Export Fundings
                                </b-dropdown-item>
                            </b-dropdown>
                        </li>

                        <li v-if="info.can_edit" class="actions-list__edit__tracker">
                            <a @click="$refs.editTracker.open(id)">Edit Tracker</a>
                        </li>

                        <li class="actions-list__add" v-if="!info.user_permissions.length && $store.state.user.company_id === constants.companyPfizer">
                            <a @click="joinTracker">Join Tracker</a>
                        </li>
                    </ul>

                </div>

                <div v-if="displayType === 'pipeline'" class="top-title">
                    <div>{{ companiesCount }} {{ stringHelper.getNoun('Startup', companiesCount) }}</div>
                    
                    <a v-if="info.unapplied_startups.length" @click="() => {openUnappliedStartupsPanel = !openUnappliedStartupsPanel}"
                       class="top-title__offset" :class="{'top-title__offset--open': openUnappliedStartupsPanel}">
                        {{info.unapplied_startups.length}} Unapplied {{stringHelper.getNoun('Signup', info.unapplied_startups.length)}}
                    </a>
                </div>

                <div class="scroll-anchor"></div>

                <div v-if="displayType === 'timeline'" class="status-page">
                    <TimelineArea
                        ref="timelineArea"
                        :tracker-id="id"
                        :startups="startups"
                        :permissions="info.user_permissions"
                        @timeline-changed="getTimelineList"
                        @removeStartup="removeCompany"
                    />

                    <div v-if="startupsLoaded && !startups.length && arrayHelper.inArray(info.user_permissions, 'tracker-startups-add')" class="start-startup">
                        <p>Add startups to your tracker to get started</p>
                        <button class="btn btn-primary btn-lg" @click="$refs.addStartups.open()">+ Add
                            Startups
                        </button>
                    </div>
                </div>

                <main class="main main--dark main--dark--tracker">
                    <div v-if="info.unapplied_startups.length" ref="left-container" class="content-tracker-offset" :class="{'content-tracker-offset--open': openUnappliedStartupsPanel}">
                        <header class="content-tracker-offset__header">
                            <p>{{ info.unapplied_startups.length === 1 ? 'This' : 'These' }} {{stringHelper.getNoun('startup', info.unapplied_startups.length)}} created a SwitchPitch account after viewing your public challenge.</p>
                            
                            <a @click="() => {openUnappliedStartupsPanel = false}" class="content-tracker-offset__close"></a>
                        </header>
                        
                        <div class="content-tracker-offset__inner">
                            <StartupPlate v-for="(startup, index) in info.unapplied_startups"
                                          :key="index"
                                          :startup="startup"
                                          :draggable="false"
                                          :addData="addCellData"
                                          :hasReviews="false"
                                          :unapplied="true"
                                          v-on:sendMessage="() => $refs.chat.open(startup.id)"
                            />

                            <StartChatPopup
                                modal-name="startStartupChatModal"
                                ref="chat"
                                contactsType="startup"
                            />
                        </div>
                    </div>

                    <div v-if="displayType === 'pipeline'" ref="header" class="content-tracker-board" @scroll="checkScroll"
                         v-bind:class="{'content-tracker-board--scroll': scrollPosition > 290}">

                        <div class="content-tracker-board__header" :class="{'sticky-header': stickHeader}">
                            <header v-for="(stage, stageIndex) in stagesData" :key="stageIndex"
                                    class="content-tracker-board__title">
                                {{ stage.name }} <span class="startups-cnt">({{ stage.count }})</span>
                                <a href="#" v-if="false">+</a>
                            </header>
                        </div>

                        <div class="content-tracker-board__main-wrapper" ref="right-container">
                            <div class="content-tracker-board__main">
                                <div v-for="(stage, stageIndex) in stagesData" :key="stageIndex"
                                     class="content-tracker-board__column" :class="'column-' + stage.id">

                                    <draggable handle=".draggable" v-model="stagesData[stageIndex].startups"
                                               group="startups" class="content-tracker-board__content"
                                               @change="onStartupStageChange"
                                    >

                                        <StartupPlate v-for="(startup, index) in stagesData[stageIndex].startups"
                                                      :key="startup.id"
                                                      :startup="startup"
                                                      :draggable="startup.draggable"
                                                      :addData="addCellData"
                                                      :hasReviews="info.reviews_enabled === 1"
                                                      v-on:editReview="editReview(startup.id)"
                                                      v-on:showReviews="showReviews(startup.id)"
                                                      v-on:showAnswers="showAnswers(startup.id)"
                                                      v-on:cardClicked="showDetails(startup.id)"
                                                      v-on:invite="inviteStartup"
                                                      v-on:togglePinned="togglePinned(stageIndex, index)"
                                        />
                                    </draggable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="displayType === 'list'" class="content-tracker-list position-relative">
                        <pulse-loader :loading="loading" :color="constants.spinnerColor"></pulse-loader>

                        <template v-if="listLoaded">
                            <Grid
                                :columns="columns"
                                :has-checkbox-column="true"
                                :data="startups"
                                :addCellData="addCellData"
                                :page-length="parseInt(pageLength)"
                                :total-rows-count="totalRowsCount"
                                :sort="sort"
                                :currentPage="currentPage"
                                v-on:changePage="changePage"
                                v-on:changeSort="changeSort"
                                v-on:selectedRowsChanged="changeSelectedRows"
                                v-on:selectAllResults="selectAllResults"
                                v-on:action="startupsListAction"
                            >
                            </Grid>

                            <div v-if="!allCount" class="start-startup">
                                <p v-if="info.type === 'public'">No Startups applied tracker yet</p>

                                <template v-if="arrayHelper.inArray(info.user_permissions, 'tracker-startups-add')">
                                    <template v-if="info.type === 'private' || info.type === trackerTypes.POC">
                                        <p>Add startups to your tracker to get started</p>
                                        <button class="btn btn-primary btn-lg" @click="$refs.addStartups.open()">+ Add
                                            Startups
                                        </button>
                                    </template>

                                    <template v-if="info.type === 'ecosystems'">
                                        <p>Add ecosystems to your tracker to get started</p>
                                        <button class="btn btn-primary btn-lg" @click="$refs.addEcosystems.open()">+ Add
                                            Ecosystems
                                        </button>
                                    </template>
                                </template>
                            </div>
                        </template>
                    </div>
                </main>
            </div>
        </div>

        <ManageColumns
            ref="manageColumns"
            :tableType="tableColumnsType"
            :tracker-id="id"
            :tracker-type="info.type"
            v-on:changeColumns="getColumns"
        />

        <AddStartups
            ref="addStartups"
            :trackerId="id"
            v-on:startupsAdded="startupsAdded"
        />

        <AddEcosystems
            v-if="isEnterprise"
            ref="addEcosystems"
            :trackerId="id"
            v-on:ecosystemsAdded="ecosystemsAdded"
        />

        <EditReview
            ref="editReview"
            prefix="tracker"
            @reviewSaved="reviewsUpdated"
            @closed="editReviewClosed"
        />

        <Reviews
            ref="reviews"
            :trackerId="id"
            v-on:reviewSaved="reviewsUpdated"
        />

        <FunnelCardPopup
            :tracker-id="parseInt(id)"
            :tracker-type="info.type"
            :permissions="info.user_permissions"
            v-on:editReview="(startupId) => editReview(startupId, true)"
            v-on:showReviews="showReviews"
            v-on:removeStartup="removeCompany"
            v-on:changed="getList"
            ref="funnelCardPopup"
        />

        <EditTracker
            ref="editTracker"
            :folder-id="0"
            prefix="-edit-tracker"
            v-on:trackerSaved="onTrackerSaved"
        />

        <EditTracker
            ref="addTracker"
            prefix="-add-tracker"
            v-on:trackerSaved="trackerSaved"
        />

        <DuplicateTracker
            ref="duplicateTracker"
        />

        <ViewAnswers
            v-if="info.type === 'public'"
            ref="viewAnswers"
        />

        <PdfLayout
            ref="pdfLayout"
            :startups="[pdfData]"
        />

        <EditTask
            ref="editTask"
            :tracker-id="id"
            prefix="-tracker"
        />

        <SelectFolder
            ref="selectFolder"
            v-on:folderSelected="addToFolder"
        />

        <EditDescription
            :id="id"
            ref="editDescription"
            v-on:descriptionChanged="descriptionChanged"
        />

        <SharedEcosystems
            v-if="isEnterprise"
            :tracker-id="id"
            ref="sharedEcosystems"
            v-on:trackerShared="trackerShared"
        />

        <CustomDataFilterModal
            v-if="Object.keys(trackerFieldsList).length"
            prefix="-tracker"
            :custom-options="trackerFieldsList"
            ref="trackerCustomDataFilter"
            v-on:updateCustomDataFilter="updateTrackerCustomDataFilter"
        />

        <CustomDataFilterModal
            prefix="-profile"
            ref="profileCustomDataFilter"
            v-on:updateCustomDataFilter="updateProfileCustomDataFilter"
        />

        <InviteStartup
            ref="invite"
            :tracker-id="id"
        />

        <SimilarStartups
            ref="similarStartups"
            @startupsAdded="startupsAdded"
            @startupRemoved="startupRemoved"
        />

        <b-modal id="modal-suggested-startups" title="Potential Matches" hide-footer
                 modal-class="modal-suggested-startups modal-w-866">

            <!--<h5 class="heading5 heading5--tertiary">These startups might be a good fit for your needs:</h5>-->

            <div class="keywords-block mb-3">
                <p>Suggested keywords:</p>

                <keywords
                    v-model="suggestedKeywords"
                />
            </div>

            <ul class="list-startups">
                <li v-for="(startup, index) in suggestedStartups" :key="index">
                    <div class="image-block-wrapper">
                        <div class="image-block image-block--39">
                            <img :src="LogoHelper.getLogo(startup.logo)" alt="">
                        </div>
                        <h5 class="heading5">
                            <a :href="'/startups/' + startup.id" target="_blank">{{ startup.name }}</a>
                        </h5>
                    </div>

                    <div class="list-startups__description">

                        <v-clamp autoresize :max-lines="3">
                            {{ startup.description }}
                            <template #after="{ toggle, expanded, clamped }">
                                <a v-if="expanded" class="link" @click="toggle">less</a>
                                <a v-if="clamped" class="link" @click="toggle">more</a>
                            </template>
                        </v-clamp>
                    </div>

                    <footer class="list-startups__footer">
                        <div v-if="false" class="text-center">Score: {{ startup.score }}</div>
                        <button v-if="startupIds.includes(startup.id)" :disabled="saving" class="btn btn-red btn-sm"
                                @click="removeCompany(startup.id)">- Remove from tracker
                        </button>
                        <button v-else :disabled="saving" class="button button--sm" @click="addStartup(startup.id)">+
                            Add to tracker
                        </button>
                    </footer>
                </li>
            </ul>

            <footer class="modal-suggested-startups__footer">
                <a :href="searchWithKeywordsLink" target="_blank" class="btn btn-bordered">Find More Startups</a>
            </footer>
        </b-modal>

        <ExportPdf
            ref="exportPdf"
            :public-tracker="info.type === 'public'"
            :tableType="tableColumnsType"
        />

        <Helper v-if="$store.getters.isEnterprise"
                page="trackers"
        />

        <ReviewsByField
            :tracker-id="id"
            ref="reviewsByField"
            @valueChanged="updateList"
        />

        <TrackerNotificationSettings
            v-if="info.notifications"
            :tracker-type="info.type"
            :tracker-id="id"
            :init-settings="info.notifications"
            ref="notificationSettings"
            @saved="setNotificationSettings"
        />
    </div>
</template>

<script>

import Grid from "@/components/Grid"
import trackers from "@/services/trackers.service"
import customFields from "@/services/custom-fields.service"
import trackersFolders from "@/services/trackers-folders.service"
import trackersCompanies from "@/services/trackers-companies.service"
import tags from "@/services/tags.service"
import grid from '@/mixins/grid'
import ManageColumns from '@/components/manage/ManageColumns.vue'
import AddStartups from "@/components/trackers/AddStartups"
import EditDescription from "@/components/trackers/EditDescription.vue"
import CustomFieldValue from "@/components/customFields/CustomFieldValue.vue"
import StartupPlate from "@/components/trackers/StartupPlate"
import Reviews from "@/components/trackers/Reviews"
import ViewAnswers from "@/components/trackers/ViewAnswers"
import EditReview from "@/components/trackers/EditReview"
import FunnelCardPopup from "@/components/trackers/StartupCardPopup"
import draggable from 'vuedraggable'
import Avatar from "@/components/ui/Avatar.vue";
import EditTracker from "@/components/trackers/trackerEdit/TrackerEdit.vue";
import SharedEcosystems from "@/components/trackers/SharedEcosystems";
import user from "@/services/user.service";
import PdfLayout from "@/components/startups/PdfLayout";
import exportsService from "@/services/exports.service";
import team from "@/services/team.service";
import EditTask from "@/components/tasks/EditTask";
import multiselect from "vue-multiselect";
import SelectFolder from "@/components/manage/SelectTrackerFolder";
import tasks from "@/services/tasks.service";
import Pager from "@/components/TablePager";
import TaskStatus from "@/components/customColumns/Status";
import Vue from "vue";
import DuplicateTracker from "@/components/trackers/DuplicateTracker.vue";
import {Mentionable} from "vue-mention";
import AccessSettings from "@/components/trackers/AccessSettings";
import InviteStartup from "@/components/startups/InviteStartup";
import {copyProfileLink} from "@/helpers";
import SimilarStartups from '@/components/startups/SimilarStartups.vue';
import {mapActions, mapGetters} from "vuex";
import ecosystems from "@/services/ecosystems.service";
import VClamp from "vue-clamp";
import debounce from "@/mixins/debounce";
import accountTypes from "@/constants/accountTypes";
import startups from "@/services/startups.service";
import ExportPdf from "@/components/export/ExportPdf.vue";
import {trackerTypes} from "@/constants/trackers";
import TimelineArea from "@/components/trackers/TimelineArea.vue";
import Keywords from "@/components/Keywords.vue";
import Helper from "@/components/Helper.vue";
import files from "@/services/files.service";
import ReviewsByField from "@/components/trackers/ReviewsByField.vue";
import PulseLoader from "vue-spinner/src/MoonLoader";
import CustomDataFilterModal from "@/components/search/CustomDataFilterModal.vue";
import TrackerNotificationSettings from "@/components/trackers/TrackerNotificationsSettings.vue";
import fileHelper from "@/helpers/file";

export default {
    components: {
        TrackerNotificationSettings,
        CustomDataFilterModal,
        ReviewsByField,
        Helper,
        Keywords,
        TimelineArea,
        ExportPdf,
        VClamp,
        Grid,
        ManageColumns,
        AddStartups,
        CustomFieldValue,
        StartupPlate,
        draggable,
        Avatar,
        EditReview,
        Reviews,
        EditTracker,
        ViewAnswers,
        PdfLayout,
        FunnelCardPopup,
        EditTask,
        multiselect,
        SelectFolder,
        Pager,
        TaskStatus,
        EditDescription,
        SharedEcosystems,
        DuplicateTracker,
        Mentionable,
        AccessSettings,
        InviteStartup,
        SimilarStartups,
        PulseLoader,
        AddEcosystems: () => import("@/components/trackers/AddEcosystems"),
        StartChatPopup: () => import("@/views/messages/StartChatPopup"),
    },

    mixins: [grid, debounce],

    data() {
        return {
            id: parseInt(this.$route.params.id),
            loaded: false,
            listLoaded: false,
            boardLoaded: false,
            errorMessage: "",
            tableColumnsType: 'tracker-startups',
            startups: [],
            companiesCount: 0,
            stagesData: [],
            pipelineStartups: [],
            initStagesData: [],
            hasPipeline: false,
            hasTimeline: false,
            notes: [],
            tasks: [],
            columnsLoaded: false,
            groups: [],
            tagsList: [],
            trackersList: [],
            ecosystemsList: [],
            topEcosystemsList: [],
            stagesList: [],
            sharedEcosystems: [],
            initCompaniesCount: null,
            addDataLoaded: false,
            loading: false,
            intelligenceReportLoading: false,
            reopenDetails: null,

            displayType: 'pipeline',

            info: {
                name: '',
                description: '',
                type: '',
                created: "",
                submitterFields: [],
                user_permissions: [],
                categories: [],
                has_pipeline: false,
                reviews_enabled: false,
                can_edit: false,
                only_referral: false,
                referral_url: '',
                access_type: '',
                company_owner_type: '',
                has_startups: true,
                unapplied_startups: [],
                notifications: null,
            },

            nameEditMode: false,
            newName: "",
            newStatus: "",
            notWatchStatus: false,

            addCellData: {
                tagsList: [],
                trackerId: parseInt(this.$route.params.id),
                trackerType: "",
                userPermissions: "",
                companyType: "startups",
                trackersList: [],
            },

            newNote: "",
            pdfData: {},

            pageLength: 20,

            filters: {
                search: "",
                tags: [],
                rating: 3,
                ratingFilterState: 'all',
                ratingCondition: '',
                categories: [],
                trackers: [],
                ecosystems: [],
                stages: [],
                addedByMe: false,
                addedByEcosystems: false,
                trackerFields: [],
                profileFields: [],
            },

            trackerFieldsList: {},
            profileFieldsList: {},

            serverFilters: {
                search: "",
                tags: [],
                rating: 3,
                ratingFilterState: '',
                ratingCondition: '',
                categories: [],
                trackers: [],
                ecosystems: [],
                stages: [],
                fields: [],
                addedByMe: false,
                addedByEcosystems: false,
            },

            canChangeStage: true,
            tasksListType: '',
            allCount: 0,
            openTasksTypeSelect: false,

            tasksCurrentPage: 1,
            tasksPageLength: 5,
            showCompletedTasks: false,
            tasksTotalRowsCount: 0,

            usersMentionList: [],
            mentionApplied: false,
            showActivityTab: false,
            showInfoPanel: false,

            lastLinkId: 0,

            messageRecipientsList: [],
            selectedRecipients: [],
            checkAllRecipients: false,
            scrollPosition: null,
            boardTargetPosition: 290,

            addStartupToTracker: null,
            ecosystemsFilterQuery: "",
            suggestedStartups: [],
            suggestedKeywords: [],
            suggestedKeywordsLoaded: false,
            showSuggestedStartupsClicked: false,
            suggestedStartupsLoading: false,

            saving: false,
            openUnappliedStartupsPanel: false,
            leftPanelLoaded: false,

            startupsLoaded: false,
            stickHeader: false,
        }
    },

    computed: {
        ...mapGetters({
            trackersFullList: 'trackersList',
            isEnterprise: 'isEnterprise',
        }),

        trackerTypes() {
            return trackerTypes
        },

        accountTypes() {
            return accountTypes
        },

        canEditInfo() {
            return this.info.user_permissions.indexOf('tracker-edit') >= 0
        },

        ownerEnterprise() {
            return this.info.company_owner_type === accountTypes.ENTERPRISE
        },

        companiesType() {
            return this.info.type === 'ecosystems' ? accountTypes.ECOSYSTEM : accountTypes.STARTUP
        },

        taskListTypes() {
            let list = {}
            list['my'] = 'Me'

            if (this.$store.state.user.is_admin) {
                this.groups.forEach(item => {
                    list['group-' + item.id] = item.name
                })

                list['all'] = 'Everyone'
            } else {
                list['team'] = 'Teams'
            }

            return list
        },

        tasksPagesCount() {
            return Math.ceil(this.tasksTotalRowsCount / this.tasksPageLength)
        },

        hasReadOnlyAccess() {
            if (this.$store.state.user.is_admin) {
                return false
            } else {
                let hasReadOnlyAccess = false
                this.info.permissions.forEach(permission => {
                    if (permission.id === this.$store.state.user.id) {
                        if (permission.role.name === 'Read only') {
                            hasReadOnlyAccess = true
                        } else {
                            return false
                        }
                    }
                })

                return hasReadOnlyAccess
            }
        },

        startupIds() {
            if (this.displayType === 'pipeline') {
                return this.stagesData.map(stage => stage.startups.map(startup => startup.id)).flat()
            } else {
                return this.startups.map(startup => startup.id)
            }
        },

        searchWithKeywordsLink() {
            let link = '/startups';

            if (this.suggestedKeywords.length) {
                link += '?keywords=' + this.suggestedKeywords.join('&keywords=')
            }

            return link;
        },

        notificationsEnabled() {
            return this.info.notifications.global || this.info.notifications.settings.trackers !== 'never' || this.info.notifications.settings.startups !== 'never';
        },

        canExportContacts() {
            return this.selectedRows.length &&
                (!this.$store.getters.settings.watch_embargoed || this.startups.some(startup => this.selectedRows.includes(startup.id) && !startup.embargoed));
        }
    },

    watch: {
        displayType() {
            this.startups = [];

            if (this.displayType === 'list') {
                this.getColumns()
            } else if (this.displayType === 'timeline') {
                this.getTimelineList();
            } else if (this.displayType === 'pipeline') {
                this.getStagesData(true)
            }
        },

        newStatus() {
            if (!this.notWatchStatus) {
                this.updateStatus()
            }
        },

        filters: {
            deep: true,
            handler() {
                if (this.displayType === 'pipeline') {
                    this.stagesData = [] //Cleanup stages immediately after clicking and before debounce to avoid Automated Test Fail
                }

                this.debounce(() => {
                    this.serverFilters.search = this.filters.search
                    this.serverFilters.tags = this.arrayHelper.arrayColumn(this.filters.tags, 'id')
                    this.serverFilters.rating = this.filters.rating
                    this.serverFilters.ratingCondition = this.filters.ratingCondition
                    this.serverFilters.ratingFilterState = this.filters.ratingFilterState
                    this.serverFilters.categories = this.arrayHelper.arrayColumn(this.filters.categories, 'id')
                    this.serverFilters.trackers = this.arrayHelper.arrayColumn(this.filters.trackers, 'id')
                    this.serverFilters.ecosystems = this.arrayHelper.arrayColumn(this.filters.ecosystems, 'value')
                    this.serverFilters.stages = this.arrayHelper.arrayColumn(this.filters.stages, 'id')
                    this.serverFilters.trackerFields = this.filters.trackerFields
                    this.serverFilters.profileFields = this.filters.profileFields
                    this.serverFilters.addedByMe = this.filters.addedByMe
                    this.serverFilters.addedByEcosystems = this.filters.addedByEcosystems

                    if (this.displayType === 'list') {
                        this.getList()
                    } else if (this.displayType === 'timeline') {
                        this.getTimelineList();
                    } else {
                        this.getStagesData()
                    }
                }, 500)
            }
        },

        tasksListType() {
            this.getTasks()
        },

        messageRecipientsList: {
            deep: true,
            handler() {
                let selected = []
                for (let i in this.messageRecipientsList) {
                    if (this.messageRecipientsList[i].checked) {
                        selected.push(this.messageRecipientsList[i].id)
                    }
                }

                this.selectedRecipients = selected
            },
        },

        checkAllRecipients() {
            for (let i in this.messageRecipientsList) {
                this.messageRecipientsList[i].checked = this.checkAllRecipients
            }
        },

        trackersFullList: {
            immediate: true,
            handler(trackersList) {
                this.addCellData.trackersList = trackersList
            }
        },

        openUnappliedStartupsPanel() {
            this.$nextTick(() => {
                if (!this.leftPanelLoaded) {
                    this.leftPanelLoaded = true

                    if (this.$refs['right-container'].offsetHeight < this.$refs['left-container'].offsetHeight) {
                        this.$refs['right-container'].style.height = this.$refs['left-container'].offsetHeight + 'px'
                        this.$refs['right-container'].style.maxHeight = this.$refs['left-container'].offsetHeight + 'px'
                    }
                }
            })
        },
    },

    mounted() {
        this.getInfo()

        this.fetchTrackers()

        this.$store.watch(state => {
            if (state.needUpdateStartups) {
                if (this.displayType === 'list') {
                    this.getList()
                } else if (this.displayType === 'timeline') {
                    this.getTimelineList();
                } else {
                    this.getStagesData(false, false)
                    this.getNewStartups()
                }
            }
        })

        this.$store.watch(state => {
            if (state.needUpdateTasks) {
                this.getTasks()
            }
        })

        team.getGroupsList().then(response => {
            this.groups = response.data
        })

        if (this.$store.getters.isEnterprise) {
            tags.getList().then(response => {
                this.tagsList = response.data
                this.addCellData.tagsList = response.data
            })
        }

        if (this.$route.query.activity) {
            this.showActivityTab = true
        }

        this.checkScroll()

        // retrieve param from url and process to decide whether show update
        let showUpdates = this.$route.query.show_updates

        if (showUpdates === '1') {
            this.showInfoPanel = true
            this.showActivityTab = true
        }

        if (this.$route.query.download_file) {
            let fileId = this.$route.query.download_file
            files.getFileName(fileId).then(response => {
                let fileName = response.data

                files.downloadCustomFile(fileId).then(response => {
                    this.fileHelper.download(response.data, fileName)
                })
            })
        }
    },

    methods: {
        ...mapActions(['fetchTrackers']),

        getStagesData(reload = false, loadStartupsIfEmpty = true) {
            if (reload) {
                this.stagesData = []
            }

            let filters = this.serverFilters

            trackersCompanies.getStagesData(this.id, {filters: filters}).then(response => {
                this.companiesCount = 0

                response.data.forEach(stage => {
                    let index = this.arrayHelper.findIndexByColumnValue(this.stagesData, 'id', stage.id)

                    if (index < 0) {
                        this.stagesData.push({
                            ...stage,
                            startups: [],
                            loading: false,
                        })

                        index = this.arrayHelper.findIndexByColumnValue(this.stagesData, 'id', stage.id)
                    }

                    this.stagesData[index].count = stage.count

                    this.companiesCount += parseInt(stage.count)

                    if (reload || (this.stagesData[index].count && !this.stagesData[index].startups.length && loadStartupsIfEmpty)) {
                        this.stagesData[index].startups = []
                        this.getStageStartups(stage.id)
                    }
                })

                this.initStagesData = this.arrayHelper.cloneArray(this.stagesData);
                this.loading = false;
            })
        },

        getStageStartups(stageId) {
            let index = this.arrayHelper.findIndexByColumnValue(this.stagesData, 'id', stageId)

            if (!this.stagesData[index].loading && this.stagesData[index].startups.length < this.stagesData[index].count) {

                this.stagesData[index].loading = true

                let lastLinkId = 0
                let startupsCount = this.stagesData[index].startups.length

                if (startupsCount) {
                    lastLinkId = this.stagesData[index].startups[startupsCount - 1].link_id
                }

                let filters = this.serverFilters

                trackersCompanies.getStageCompanies(this.id, stageId, lastLinkId, {filters: filters}).then(response => {
                    this.stagesData[index].startups = this.stagesData[index].startups.concat(response.data)

                    if (this.stagesData[index].startups.length && this.stagesData[index].startups[0].link_id > this.lastLinkId) {
                        this.lastLinkId = this.stagesData[index].startups[0].link_id
                    }

                    this.initStagesData = this.arrayHelper.cloneArray(this.stagesData)

                    this.stagesData[index].loading = false
                })
            }
        },

        getNewStartups() {
            let filters = this.serverFilters

            trackersCompanies.getNewCompanies(this.id, this.lastLinkId, {filters: filters}).then(response => {
                for (let stageId in response.data) {
                    let index = this.arrayHelper.findIndexByColumnValue(this.stagesData, 'id', stageId)

                    if (index >= 0) {
                        response.data[stageId].forEach(item => {
                            this.stagesData[index].startups.unshift(item)

                            if (item.link_id > this.lastLinkId) {
                                this.lastLinkId = item.link_id
                            }
                        })
                    }
                }

                this.initStagesData = this.arrayHelper.cloneArray(this.stagesData)
            })
        },

        checkScroll() {
            this.stagesData.forEach(item => {

                let columns = document.getElementsByClassName('column-' + item.id)

                if (columns.length) {
                    let column = columns[0]
                    let cards = column.getElementsByClassName('card')

                    if (cards.length) {
                        let card = cards[cards.length - 1]

                        if (card.getBoundingClientRect().bottom - window.innerHeight < 0) {
                            this.getStageStartups(item.id)
                        }
                    }
                }
            })

            let header = this.$refs['header'];

            if (header) {
                this.stickHeader = (header.getBoundingClientRect().bottom - header.getBoundingClientRect().height) < 0;
            }

            this.scrollPosition = window.scrollY
            //ToDo: Need to exit from scrolling mode
            // if (this.scrollPosition > 290 ) {
            //     document.documentElement.style.overflow = "hidden"
            // } else {
            //     document.documentElement.style.overflow = "auto"
            // }
        },

        getList() {
            if (this.columnsLoaded) {
                this.loading = true;
                let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
                let sort = this.sort
                let filters = this.serverFilters
                let currentPage = this.currentPage
                let pageLength = this.pageLength

                let listParams = {columns, sort, filters, currentPage, pageLength}

                trackersCompanies.getList(this.id, listParams).then(response => {
                    this.startups = response.data.data
                    this.startupsLoaded = true;
                    this.totalRowsCount = parseInt(response.data.total_count)
                    this.allCount = parseInt(response.data.all_count)
                    this.currentPage = response.data.current_page
                    this.listLoaded = true
                    this.companiesCount = this.totalRowsCount

                    if (this.initCompaniesCount === null) {
                        this.initCompaniesCount = this.companiesCount
                    }
                }).finally(() => {
                    this.loading = false;
                })
            } else {
                this.getColumns()
            }
        },

        getTimelineList() {
            let filters = this.serverFilters
            let currentPage = this.currentPage
            let pageLength = this.pageLength

            let listParams = {filters, currentPage, pageLength}

            trackersCompanies.getTimelineList(this.id, listParams).then(response => {
                this.startupsLoaded = true;
                this.startups = response.data.data
            });
        },

        getStartupData(startupId) {
            trackersCompanies.getStartupData(this.id, startupId).then(response => {
                for (let i in this.stagesData) {
                    let index = this.arrayHelper.findIndexByColumnValue(this.stagesData[i].startups, 'id', startupId)

                    if (index >= 0) {
                        for (let field in response.data) {
                            this.stagesData[i].startups[index][field] = response.data[field]
                        }

                        break
                    }
                }
            })
        },

        getInfo(reloadStages = false) {
            trackers.getInfo(this.id).then(response => {
                this.info = response.data
                document.title = this.info.name + ' | SwitchPitch'

                if (
                    !this.$store.getters.settings.disable_ai
                    && !this.info.has_startups && this.$store.getters.isPremiumEnterprise && this.info.type === 'private'
                    && this.info.user_permissions.includes('tracker-startups-add') && this.info.enable_nlp
                ) {
                    this.showSuggestedStartups();
                }


                if (this.info.type === 'ecosystems') {
                    this.tableColumnsType = 'tracker-ecosystems'
                    this.addCellData.companyType = 'ecosystems'
                }

                this.displayType = response.data.default_view
                this.loaded = true
                this.notWatchStatus = true
                this.newStatus = this.info.status

                this.$nextTick(() => {
                    this.notWatchStatus = false
                })

                this.addCellData.trackerType = this.info.type
                this.addCellData.userPermissions = this.info.user_permissions

                if (response.data.pipeline_id) {
                    this.hasPipeline = true

                    if (this.displayType === 'pipeline') {
                        this.getStagesData(reloadStages)
                        this.getStagesList()
                    }
                } else {
                    this.hasPipeline = false;

                    if (response.data.timeline_preset_id) {
                        this.displayType = 'timeline'
                    } else {
                        this.displayType = 'list'
                    }
                }

                if (!this.addDataLoaded) {
                    this.getNotes()
                    this.getTagsList()
                    this.getTrackerFieldsForFilter()
                    this.getProfileFieldsForFilter()
                    this.getUsersMentionList()

                    if (this.isEnterprise) {
                        this.getSharedEcosystems()
                    }

                    user.getTaskType(0, this.id).then(response => {
                        this.tasksListType = response.data
                    })

                    this.addDataLoaded = true
                }

                //Load trackers list for filter when tracker info data is loaded (need company type)
                this.getTrackersList()

                if (this.info.type !== 'ecosystems') {
                    this.getTopEcosystemsList()
                }
            }).catch(error => {
                this.errorMessage = error.response.data.message
            })

            trackers.getMessageRecipientsList(this.id).then(response => {
                this.messageRecipientsList = this.arrayHelper.addColumn(response.data, 'checked', true)
            })
        },

        getSharedEcosystems() {
            trackers.getSharedEcosystems(this.id).then(response => {
                this.sharedEcosystems = response.data

                if (this.sharedEcosystems.length && this.info.access_type === 'Private') {
                    this.info.access_type = 'Custom'
                }
            })
        },

        getNotes() {
            trackers.getNotes(this.id).then(response => {
                this.notes = response.data
            })
        },

        getTagsList() {
            if (this.$store.getters.isEnterprise) {
                tags.getList().then(response => {
                    this.addCellData.tagsList = response.data
                })
            }
        },

        getTasks(forExport = false) {
            if (this.$store.getters.isEnterprise) {
                let currentPage = this.tasksCurrentPage
                let pageLength = this.tasksPageLength
                let search = ""
                let showCompleted = this.showCompletedTasks

                tasks.getIndex(this.tasksListType, 0, this.id, 0,
                    {
                        forExport,
                        search,
                        showCompleted
                    },
                    {
                        currentPage,
                        pageLength
                    })
                    .then(response => {
                    if (!forExport) {
                        this.tasks = response.data.data
                        this.tasksTotalRowsCount = parseInt(response.data.total_count)
                        this.tasksCurrentPage = response.data.current_page
                    } else {
                        this.fileHelper.download(response.data, 'tasks.csv')
                    }
                })
            }
        },

        getTrackersList() {
            trackers.getList(this.info.type).then(response => {
                this.trackersList = []
                response.data.forEach(item => {
                    if (item.value !== this.id) {
                        this.trackersList.push(item)
                    }
                })
            })
        },

        getTopEcosystemsList() {
            if (!this.$store.getters.isEcosystem) {
                ecosystems.getTopListForTracker(this.id).then(response => {
                    this.topEcosystemsList = this.arrayHelper.convertForFilter(response.data)

                    if (!this.ecosystemsFilterQuery.length) {
                        this.ecosystemsList = this.topEcosystemsList
                    }
                })
            }
        },

        updateEcosystemsList(query) {
            this.ecosystemsFilterQuery = query

            if (query.length) {
                ecosystems.getListForFilter(query).then(response => {
                    if (this.ecosystemsFilterQuery.length) {
                        this.ecosystemsList = this.arrayHelper.convertForFilter(response.data)
                    } else {
                        this.ecosystemsList = this.topEcosystemsList
                    }
                })
            } else {
                this.ecosystemsList = this.topEcosystemsList
            }
        },

        getStagesList() {
            trackers.getTrackersStages([this.id]).then(response => {
                this.stagesList = response.data
            })
        },

        getTrackerFieldsForFilter() {
            trackers.getDiligenceFieldsForFilter(this.id).then(response => {
                if (Object.keys(response.data).length) {
                    this.trackerFieldsList = response.data
                }
            })
        },

        getProfileFieldsForFilter() {
            customFields.getNotStaticFields('company_diligence').then(response => {
                if (Object.keys(response.data).length) {
                    this.profileFieldsList = response.data
                }
            })
        },

        changeTasksPage(page) {
            this.tasksCurrentPage = page
            this.getTasks()
        },

        updateName() {
            let data = {
                name: this.newName
            }

            trackers.update(this.id, data).then(() => {
                this.info.name = data.name
                this.getInfo()
                this.nameEditMode = false
            })
        },

        updateStatus() {
            let data = {
                status: this.newStatus
            }

            trackers.update(this.id, data).then(() => {
                this.getInfo()
            })
        },

        openManageColumns() {
            this.$refs.manageColumns.open()
        },

        startupsAdded() {
            if (this.displayType === 'list' && this.listLoaded) {
                this.getList()
            }
            else if (this.displayType === 'timeline') {
                this.getTimelineList();
            }
            else if (this.displayType === 'pipeline') {
                this.getStagesData(false, false)
                this.getNewStartups()
            }
        },

        ecosystemsAdded() {
            this.getList()
        },

        startupRemoved(startupId) {
            if (this.displayType === 'list') {
                this.getList()
            } else if (this.displayType === 'timeline') {
                this.getTimelineList();
            } else if (this.displayType === 'pipeline') {
                this.removeStartupFromStageData(startupId)
            }
        },

        editReview(startupId, fromModal = false) {
            this.$refs.editReview.open(this.id, startupId)

            if (fromModal) {
                this.reopenDetails = startupId;
                this.$refs.funnelCardPopup.close();
            }
        },

        editReviewClosed() {
            if (this.reopenDetails) {
                this.$refs.funnelCardPopup.open(this.reopenDetails);
                this.reopenDetails = null;
            }
        },

        showReviews(startupId) {
            this.$refs.reviews.open(this.id, startupId)
        },

        reviewsUpdated(startupId) {
            if (this.displayType === 'pipeline') {
                this.getStartupData(startupId)
            } else {
                this.getList()
            }

            this.$refs.funnelCardPopup.getData()
        },

        showAnswers(startupId) {
            this.$refs.viewAnswers.open(this.id, startupId)
        },

        showDetails(startupId) {
            this.$refs.funnelCardPopup.open(startupId)
        },

        archiveTracker() {
            trackers.update(this.id, {status: 'archive'}).then(() => {
                this.info.status = 'archive'
            })
        },

        unarchiveTracker() {
            trackers.update(this.id, {status: 'draft'}).then(() => {
                this.info.status = 'draft'
            })
        },

        getColumns() {
            this.columns = [];
            this.loading = true;

            user.getColumnsForTracker(this.id).then(response => {
                let columns = response.data
                this.columns = []

                for (let i in columns) {
                    let column = columns[i]

                    if (columns[i].name === 'name') {
                        column.component = 'SearchCompanyNameColumn'
                    } else if (columns[i].name === 'location') {
                        column.component = 'SearchCompanyLocationColumn'
                    } else if (
                        columns[i].name.indexOf('custom_') === 0 ||
                        columns[i].name.indexOf('diligence_') === 0 ||
                        columns[i].name.indexOf('question_') === 0
                    ) {
                        column.component = 'CustomField'
                    } else if (columns[i].name.indexOf('review_') === 0) {
                        column.component = 'Link'
                    } else if (columns[i].name === 'reviews') {
                        column.component = 'ReviewsColumn'
                    } else if (columns[i].name === 'irl_score') {
                        column.component = 'IRLScore'
                    } else if (columns[i].name === 'ecosystems' || columns[i].name === 'website') {
                        column.component = 'RawColumn'
                    } else if (columns[i].name === 'submission') {
                        column.component = 'Actions'
                    }

                    this.columns.push(column)
                }

                if (this.info.type === 'public') {
                    this.columns.push(
                        {
                            name: 'actions',
                            label: 'Actions',
                            component: 'Actions',
                        }
                    )
                }

                this.columnsLoaded = true
                this.getList()
            })
        },

        addNote() {
            if (this.mentionApplied) {
                this.$nextTick(() => {
                    this.mentionApplied = false
                })
            } else if (this.newNote.length) {
                let data = {
                    text: this.newNote,
                    recipients: this.selectedRecipients,
                }

                this.newNote = ""

                trackers.addNote(this.id, data).then(() => {
                    this.getNotes()
                })
            }
        },

        removeCompanies() {
            if (this.selectedAllResults) {
                if (confirm(this.totalRowsCount + " " + ((this.info.type === 'ecosystems') ? this.stringHelper.getNoun('ecosystem', this.totalRowsCount) : this.stringHelper.getNoun('startup', this.totalRowsCount)) + " will be removed from tracker. Do you want to proceed?")) {
                    trackersCompanies.removeAllCompanies(this.id, this.serverFilters).then(() => {
                        this.getList()

                        if (this.info.type !== 'ecosystems') {
                            this.getTopEcosystemsList()
                        }
                    })
                }
            } else {
                if (confirm(this.selectedRows.length + " " + ((this.info.type === 'ecosystems') ? this.stringHelper.getNoun('ecosystem', this.selectedRows.length) : this.stringHelper.getNoun('startup', this.selectedRows.length)) + " will be removed from tracker. Do you want to proceed?")) {
                    trackersCompanies.removeCompanies(this.id, this.selectedRows).then(() => {
                        this.getList()

                        if (this.info.type !== 'ecosystems') {
                            this.getTopEcosystemsList()
                        }
                    })
                }
            }
        },

        removeCompany(companyId) {
            this.saving = true
            trackersCompanies.removeCompanies(this.id, [companyId]).then(() => {
                if (this.displayType === 'list') {
                    this.getList()
                } else if (this.displayType === 'timeline') {
                    this.getTimelineList();
                } else {
                    this.removeStartupFromStageData(companyId)
                }

                if (this.info.type !== 'ecosystems') {
                    this.getTopEcosystemsList()
                }

                this.saving = false
            })
        },

        removeStartupFromStageData(startupId) {
            for (let stageId in this.stagesData) {
                let index = this.arrayHelper.findIndexByColumnValue(this.stagesData[stageId].startups, 'id', startupId)

                if (index >= 0) {
                    this.stagesData[stageId].startups.splice(index, 1)
                    this.getStagesData()
                    break
                }
            }
        },

        copyPublicLink() {
            let link
            if (this.info.only_referral) {
                link = document.location.origin + '/public-trackers/' + this.info.referral_url
            } else {
                link = document.location.origin + '/public-trackers/' + this.id
            }

            navigator.clipboard.writeText(link)
        },

        copyInternalLink: copyProfileLink('trackers'),

        deleteTracker() {
            if (confirm("Delete tracker?")) {
                trackers.delete(this.id).then(() => {
                    this.$router.push('/trackers')
                })
            }
        },

        addToFolder(folder) {
            trackersFolders.addTrackers(folder.id, [this.id]).then(() => {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: "Tracker added to " + folder.name + " folder."
                })
            })
        },

        startupsListAction(params) {
            if (params.action === 'diligenceFieldChanged') {
                this.getList()
            } else if (params.action === 'show-reviews') {
                this.showReviews(params.rowId)
            } else if (params.action === 'edit-review') {
                this.editReview(params.rowId)
            } else if (params.action === 'view-answers') {
                this.showAnswers(params.rowId)
            } else if (params.action === 'pdf') {
                let startupName = this.startups.find(item => item.id === params.rowId).name.name
                this.$refs.exportPdf.open(params.rowId, startupName, this.id)
            } else if (params.action === 'add-tracker') {
                this.addStartupToTracker = params.rowId
                this.$refs.addTracker.open(0, true)
            } else if (params.action === 'showReviews') {
                let fieldId = params.params;
                let title = fieldId ? this.columns.find(item => item.name === 'review_' + fieldId).label : 'Review Note';

                this.$refs.reviewsByField.open(title, params.rowId, fieldId);
            }
        },

        exportCSV() {
            let result
            if (this.selectedAllResults || !this.selectedRows.length) {
                result = exportsService.exportAllCompaniesFromTracker(this.id, this.columns, this.addCellData.companyType, this.serverFilters)
            } else {
                let startups = this.selectedRows
                result = exportsService.exportCompaniesFromTracker(this.id, startups, this.columns, this.addCellData.companyType)
            }

            result.then(response => {
                this.fileHelper.download(response.data, this.info.name + '.csv')
            })
        },

        openExportPdf() {
            this.$refs.exportPdf.openForTracker(this.info.name, this.id, this.info.type, this.selectedAllResults ? [] : this.selectedRows, this.serverFilters)
        },

        exportPPT(advanced = false) {
            let filters = {};
            let startups_ids = [];

            if (this.selectedAllResults) {
                filters = {
                    ...this.serverFilters,
                    tracker_id: this.id,
                }
            } else {
                startups_ids = this.selectedRows
            }

            exportsService.generateStartupsPpt(advanced, startups_ids, filters).then(response => {
                this.fileHelper.download(response.data, this.info.name + '.pptx')
            })
        },

        exportContacts() {
            let result
            if (this.selectedAllResults) {
                result = exportsService.exportAllCompaniesContactsFromTracker(this.id, this.serverFilters)
            } else {
                result = exportsService.exportCompaniesContacts(this.companiesType, this.selectedRows)
            }

            result.then(response => {
                let fileName;
                if (!this.selectedAllResults && this.selectedRows.length === 1) {
                    let company = this.startups.find(startup => startup.id === this.selectedRows[0])
                    fileName = company.name.name + '\'s contacts.csv'
                } else {
                    fileName = this.info.name + ' - contacts.csv'
                }
                this.fileHelper.download(response.data, fileName)
            })
        },

        exportFundings() {
            let result
            if (this.selectedAllResults) {
                result = exportsService.exportAllStartupsFundingsFromTracker(this.id, this.serverFilters)
            } else {
                let startups = this.selectedRows
                result = exportsService.exportStartupsFundingsFromTracker(this.id, startups)
            }

            result.then(response => {
                this.fileHelper.download(response.data, this.info.name + ' - fundings.csv')
            })
        },

        changeTaskStatus(params) {
            let status = params.status
            tasks.update(params.rowId, {status: status}).then(() => {
                this.getTasks()
            })
        },

        editTask(id) {
            this.$refs.editTask.open(id)
        },

        deleteTask(id) {
            if (confirm('Remove task?')) {
                tasks.delete(id).then(() => {
                    this.getTasks()
                })
            }
        },

        share() {
            if (!this.info.description) {
                this.$refs.editDescription.open()
            } else {
                this.$refs.sharedEcosystems.open()
            }
        },

        revokeShareEcosystem(id) {
            if (confirm("Revoke Ecosystem Access?")) {
                trackers.revokeShareWithEcosystems(this.id, id).then(() => {
                    this.getSharedEcosystems()
                })
            }
        },

        descriptionChanged(description) {
            trackers.update(this.id, {description: description}).then(() => {
                this.info.description = description
                this.share()
            })
        },

        trackerShared(count) {
            this.getSharedEcosystems()

            Vue.notify({
                group: 'app',
                type: 'success',
                duration: 5000,
                text: "Tracker shared with " + count + " " + this.stringHelper.getNoun('ecosystem', count)
            })
        },

        openTrackerCustomDataFilter() {
            this.$refs.trackerCustomDataFilter.open()
        },

        openProfileCustomDataFilter() {
            this.$refs.profileCustomDataFilter.open()
        },

        updateTrackerCustomDataFilter(filters) {
            this.filters.trackerFields = filters
        },

        updateProfileCustomDataFilter(filters) {
            this.filters.profileFields = filters
        },

        getUsersMentionList() {
            team.getUsersMentionList().then(response => {
                this.usersMentionList = response.data
            })
        },

        joinTracker() {
            trackers.join(this.id).then(response => {
                if (response.data) {
                    alert(response.data);
                } else {
                    this.getInfo();
                }
            })
        },

        changeVisibility() {
            this.$refs.accessSettings.open()
        },

        accessSettingsChanged(params) {
            trackers.saveAccessSettings(this.id, params).then(() => {
                this.getInfo()
                this.getSharedEcosystems()
            })
        },

        inviteStartup(id) {
            this.$refs.invite.open(id)
        },

        applyMention(item) {
            this.mentionApplied = true

            let index = this.arrayHelper.findIndexByColumnValue(this.messageRecipientsList, 'id', item.id)
            if (index < 0) {
                this.messageRecipientsList.push({
                    id: item.id,
                    name: item.name,
                    avatarUrl: item.avatar,
                    checked: true,
                })
            }
        },

        trackerSaved(trackerId) {
            this.fetchTrackers(true);
            this.getInfo();

            if (this.addStartupToTracker) {
                trackersCompanies.addCompanies([trackerId], [this.addStartupToTracker]).then(() => {

                    startups.getTrackers(this.addStartupToTracker).then(response => {
                        let index = this.arrayHelper.findIndexByColumnValue(this.startups, "id", this.addStartupToTracker)
                        this.startups[index].name.trackersCount = response.data.length

                        this.addStartupToTracker = null
                    })
                })
            }
        },

        addStartup(startupId) {
            this.saving = true
            trackersCompanies.addCompanies([this.id], [startupId]).then(() => {
                this.startupsAdded()
                this.saving = false
            })
        },

        onTrackerSaved() {
            this.getInfo(true);

            if (this.isEnterprise) {
                this.getSharedEcosystems()
            }

            this.getTrackerFieldsForFilter()
        },

        onStartupStageChange(evt) {
            if ('added' in evt) {

                let changed = [];
                let sendEmail = false;

                for (let stage in this.stagesData) {
                    let initStage = this.initStagesData[stage]
                    let changedCompanies = []

                    this.stagesData[stage].startups.forEach(startup => {

                        if (!initStage.startups.find(item => item.id === startup.id)) {
                            changedCompanies.push(startup.id)
                            sendEmail = this.stagesData[stage].send_email;
                        }
                    })

                    if (changedCompanies.length) {
                        changed.push({
                            id: initStage.id,
                            companies: changedCompanies,
                        })
                    }
                }

                if (changed.length) {
                    if (!sendEmail || confirm("Startup will be notified about stage change. Would you like to proceed?")) {
                        this.loading = true;

                        trackersCompanies.savePipelineCompanies(this.id, {stagesCompanies: changed}).then(response => {
                            let updatedStartups = response.data;

                            for (let stageId in updatedStartups) {
                                let stage = this.stagesData.find(item => parseInt(item.id) === parseInt(stageId));

                                for (let startupId in updatedStartups[stageId]) {
                                    let startupIndex = stage.startups.findIndex(item => parseInt(item.id) === parseInt(startupId));
                                    stage.startups[startupIndex] = updatedStartups[stageId][startupId];
                                }
                            }

                            let needReload = false;

                            if (this.filters.stages.length) {
                                needReload = true;
                            }

                            this.getStagesData(needReload);
                        })
                    } else {
                        this.stagesData = this.arrayHelper.cloneArray(this.initStagesData);
                    }
                }
            }
        },

        showSuggestedStartupsManual() {
            this.suggestedStartupsLoading = true;
            this.showSuggestedStartupsClicked = true;
            this.showSuggestedStartups();
        },

        async showSuggestedStartups() {
            this.suggestedStartups = [];

            if (!this.suggestedKeywords.length && !this.suggestedKeywordsLoaded) {
                let response = await trackers.getSuggestedKeywords(this.id);
                this.suggestedKeywords = response.data;
                this.suggestedKeywordsLoaded = true;
            }

            if (this.suggestedKeywords.length) {
                trackers.getSuggestedStartups(this.id, this.suggestedKeywords).then(response => {

                    if (response.data.length) {
                        this.suggestedStartups = response.data
                        this.$bvModal.show('modal-suggested-startups')
                    } else if (this.showSuggestedStartupsClicked) {
                        Vue.notify({
                            group: 'app',
                            type: 'error',
                            duration: 5000,
                            text: "No suggested startups found"
                        })
                    }
                }).finally(() => {
                    this.suggestedStartupsLoading = false;
                })
            } else {
                this.suggestedStartupsLoading = false;

                if (this.showSuggestedStartupsClicked) {
                    Vue.notify({
                        group: 'app',
                        type: 'error',
                        duration: 5000,
                        text: "No suggested keywords found"
                    })
                }
            }
        },

        togglePinned(stageIndex, startupIndex) {
            this.stagesData[stageIndex].startups[startupIndex].pinned = !this.stagesData[stageIndex].startups[startupIndex].pinned;

            trackersCompanies.setPinned(this.id, this.stagesData[stageIndex].startups[startupIndex].id, this.stagesData[stageIndex].startups[startupIndex].pinned).then(() => {
                this.stagesData[stageIndex].startups = [
                    ...this.stagesData[stageIndex].startups.filter(startup => startup.pinned),
                    ...this.stagesData[stageIndex].startups.filter(startup => !startup.pinned)
                ];
            })
        },

        showNotificationSettings() {
            this.$refs.notificationSettings.open();
        },

        setNotificationSettings(settings) {
            trackers.saveNotificationsSettings(this.id, settings).then(() => {
                this.getInfo();
            });
        },

        async getIntelligenceReport() {
            this.intelligenceReportLoading = true;

            try {
                const {data} = await trackers.getIntelligenceReport(this.id);
                let currentDate = new Date();

                fileHelper.download(data,`${this.info.name}-${currentDate.toLocaleDateString()}.pdf`);
            } finally {
                this.intelligenceReportLoading = false;
            }
        },
    },

    /* Listen global window scroll to automatically load new cards */
    created: function () {
        window.addEventListener('scroll', this.checkScroll);
    },

    destroyed: function () {
        window.removeEventListener('scroll', this.checkScroll);
    }
}
</script>

<style scoped>
.link-info {
    padding: 0;
    background-color: transparent !important;
    border: none;
}

.popover {
    max-width: 160px !important;

    padding: 5px 10px;
    background: #fff;
    border-radius: 4px;
    color: #7b7b7b !important;
    filter: drop-shadow(1px 3px 4px rgba(#000, 0.3));
    box-shadow: none !important;
    font-size: 10px !important;
    font-weight: 400;
    line-height: 1.5;
}
</style>
