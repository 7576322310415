import {ref, watch} from "vue";
import savedSearchesService from "@/services/saved-searches.service";
import usersSearchesService from "@/services/users-searches.service";

export default function (searchType, initSavedSearch = null) {
    const savedSearch = ref(null);
    const refinedSavedSearch = ref(null);
    const savedSearches = ref([]);

    watch(savedSearch, async (value) => {
        if (value) {
            let rules = processBackwardCompatibility(JSON.parse(value.rules));

            refinedSavedSearch.value = {
                ...value,
                rules: rules,
            }
        }
    })

    async function getSavedSearches() {
        const {data} = await savedSearchesService.getList(searchType);

        savedSearches.value = data;

        if (savedSearch.value && !findSavedSearch(savedSearch.value.id)) {
            savedSearch.value = null
        }

        if (initSavedSearch) {
            let foundSavedSearch = findSavedSearch(parseInt(initSavedSearch));

            if (foundSavedSearch) {
                savedSearch.value = foundSavedSearch;
            } else {
                window.location.href = "/startups"
            }
        }
    }

    async function getUserSearch(searchId) {
        const {data} = await usersSearchesService.getQuery(searchId);

        if (data) {
            let rules = processBackwardCompatibility(JSON.parse(data.rules));

            return {
                ...data,
                rules: rules,
            }
        }

        return null;
    }

    function findSavedSearch(id) {
        return savedSearches.value.map(item => item.items).flat().find(item => item.id === id)
    }

    function processBackwardCompatibility(rules) {
        let formattedRules = []
        let lastId = 1

        rules.forEach(rule => {
            let item = {}

            if (typeof rule.condition !== "undefined") {
                item.condition = rule.condition
                item.rules = []

                rule.rules.forEach(conditionalRule => {
                    if (conditionalRule.name === "categories") {
                        conditionalRule.value.forEach(category => {
                            let formattedItem = {
                                "id": lastId++,
                                "name": "categories",
                                "label": "Category",
                                "value": [category],
                                "valueAlias": category,
                                "negative": conditionalRule.negative,
                            }

                            item.rules.push(formattedItem)
                        })
                    } else {
                        correctRule(conditionalRule);
                        conditionalRule.id = lastId++
                        item.rules.push({...conditionalRule})
                    }
                })
            } else {
                if (rule.name === "categories") {
                    if (rule.value.length > 1) {
                        item.condition = "OR"
                        item.rules = []
                    }

                    rule.value.forEach(category => {
                        let formattedItem = {
                            "id": lastId++,
                            "name": "categories",
                            "label": "Category",
                            "value": [category],
                            "valueAlias": category,
                            "negative": rule.negative,
                        }

                        if (rule.value.length > 1) {
                            item.rules.push(formattedItem)
                        } else {
                            item = formattedItem
                        }
                    })
                } else {
                    correctRule(rule);
                    rule.id = lastId++
                    item = {...rule}
                }
            }

            formattedRules.push(item)
        })

        function correctRule(rule) {
            if (
                rule.name === "keyword" &&
                rule.groupWords.length &&
                rule.suggestedGroupWords === undefined
            ) {
                rule.suggestedGroupWords = rule.groupWords
            }
        }

        return formattedRules;
    }

    return {
        savedSearch,
        refinedSavedSearch,
        savedSearches,
        getUserSearch,
        getSavedSearches,
    }
}
