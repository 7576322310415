<script setup>
import {computed, ref, watch} from "vue";
import {useStore} from "vue2-helpers/vuex";

const store = useStore();
const input = ref(null);
const searchWord = ref('');
const aiMode = ref(false);
const searchType = ref('startups');
const showModal = ref(false);

const canAISearch = computed(() => !store.getters.settings.disable_ai);
const placeholder = computed(() => (searchType.value === 'startups' && aiMode.value) ? 'Describe what you are looking for' : 'What are you looking for?');

watch(() => store.getters.settings, () => {
    if (canAISearch.value) {
        aiMode.value = true;
    }
}, {immediate: true});

function focusSearchInput() {
    input.value.focus()
}

function searchByWord() {
    if (searchType.value === 'startups' && aiMode.value) {
        window.location.href = '/' + searchType.value + '?ai_prompt=' + searchWord.value;
    } else {
        window.location.href = '/' + searchType.value + '?keywords=' + searchWord.value;
    }
}

</script>

<template>
    <div>
        <div id="top-search" class="top-search" @click="showModal = true"></div>
        <b-modal id="top-search-modal" modal-class="modal-w-md" v-model="showModal" @shown="focusSearchInput" hide-header-close hide-footer hide-title centered>
            <button type="button" aria-label="Close" class="close" @click="showModal = false">×</button>

            <div class="top-search__for">
                Searching for

                <div
                    class="top-search__for__label-startups"
                    :class="{'active': searchType === 'startups'}"
                    @click="searchType = 'startups'"
                >
                    Startups
                </div>

                <div
                    class="top-search__for__label-ecosystems"
                    :class="{'active': searchType === 'ecosystems'}"
                    @click="searchType = 'ecosystems'"
                >
                    Ecosystems
                </div>
            </div>
            
            <div class="header__form-search form-search form-search-primary-wrapper">
                    <input
                        :placeholder="placeholder"
                        type="text"
                        v-model="searchWord"
                        ref="input"
                        @keyup.enter="searchByWord"
                    />

                    <div class="ai-switcher" v-if="canAISearch && searchType === 'startups'">
                        <input
                            v-model="aiMode"
                            id="ai-switcher-top"
                            type="checkbox"
                            @click="aiMode = !aiMode"
                        />

                        <label
                            for="ai-switcher-top"
                            class="ai-switcher__label"
                        >
                            <span class="ai-switcher__label__inner"></span>
                        </label>
                    </div>
            </div>
        </b-modal>
    </div>
</template>

<style scoped>

</style>
