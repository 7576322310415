import axios from '@/axios'

const RESOURCE_NAME = '/agency-clients';

export default {
    getClients(search = '') {
        return axios.post(`${RESOURCE_NAME}/get-clients?search=${search}`);
    },

    loginToClient(id) {
        return axios.post(`${RESOURCE_NAME}/login-to-client?id=${id}`);
    },

    returnToMainAccount() {
        return axios.post(`${RESOURCE_NAME}/return-to-main-account`);
    },
}
