<script setup>
import {computed, inject, ref, watch} from "vue";
import useGrid from "@/composables/useGrid";
import useCurrentPageData from "@/composables/useCurrentPageData";
import TablePager from "@/components/TablePager.vue";
import Avatar from "vue-avatar";
import constants from "@/constants/constants";
import tasksService from "@/services/tasks.service";
import {useStore} from "vue2-helpers/vuex";
import EditTaskModal from "@/components/tasks/EditTask.vue";
import fileHelper from "@/helpers/file";
import AccountTypes from "@/constants/accountTypes";

const store = useStore();
const {gridParams, gridMethods} = useGrid(getTasks, 5);
const getPageItems = useCurrentPageData();
const companyType = inject('companyType', null);
const companyId = inject('companyId', 0);
const trackerId = inject('trackerId', 0);
const getTasksIndexList = inject('getTasksIndexList');
const initListType = inject('tasksListType');

defineProps(['tasksCount']);
const emit = defineEmits(['tasksChanged']);

const showModal = ref(false);
const tasks = ref([]);
const listLoaded = ref(false);
const listType = ref("");
const search = ref("");
const openTypeSelect = ref(false);
const showCompleted = ref(false);

const editTaskModal = ref(null);

const taskListTypes = computed(() => {
    let list = {
        my: 'Me',
    }

    if (store.state.user.is_admin) {
        store.getters.groupsList.forEach(item => {
            list['group-' + item.id] = item.name
        })

        list['all'] = 'Everyone'
    } else {
        list['team'] = 'Teams'
    }

    return list

})

watch([listType, showCompleted], getTasks);

function getFilteredTasksList(pageParams) {
    return getTasksIndexList(listType.value, {
        forExport: false,
        search: search.value,
        showCompleted: showCompleted.value
    }, pageParams);
}

async function getTasks() {
    tasks.value = await getPageItems(getFilteredTasksList, gridParams);
    listLoaded.value = true;
}

async function exportTasks() {
    const {data} = await getTasksIndexList(listType.value, {
        forExport: true,
        search: search.value,
        showCompleted: showCompleted.value
    });

    fileHelper.download(data, 'tasks.csv');
}

function taskChanged() {
    getTasks();
    emit('tasksChanged');
}

async function changeStatus(taskId, status) {
    await tasksService.update(taskId, {status: status});
    await getTasks();
}

function addTask() {
    showModal.value = false;
    editTaskModal.value.open();
}

function editTask(id) {
    showModal.value = false;
    editTaskModal.value.open(id);
}

function editTaskClosed() {
    showModal.value = true;
}

async function removeTask(id) {
    if (confirm('Remove task?')) {
        await tasksService.delete(id);
        emit('tasksChanged');
        await getTasks();
    }
}

async function open() {
    store.dispatch('fetchGroups');

    if (!listLoaded.value) {
        listType.value = initListType;
        gridParams.currentPage = 1;
        getTasks();
    }

    showModal.value = true;
}

defineExpose({open});

</script>

<template>
    <div>
        <b-modal v-model="showModal" :title="tasks.length ? 'Tasks' : ''" hide-footer modal-class="modal-manage modal-w-xxl">
            <template v-if="tasksCount" #modal-header>
                <div class="d-flex justify-content-between">
                    <h4 class="heading4 d-flex flex-wrap mr-5">Tasks Assigned to
                        <div
                            class="tasks__select"
                            v-click-outside="() => openTypeSelect = false"
                        >
                            <div
                                class="tasks__select__chosen"
                                @click="openTypeSelect = true"
                            >
                                <span :title="taskListTypes[listType]">
                                    {{taskListTypes[listType]}}
                                </span>
                            </div>

                            <ul v-show="openTypeSelect" class="multiselect__content">
                                <li
                                    v-for="(name, value) in taskListTypes"
                                    :key="value"
                                    class="multiselect__element"
                                    @click="listType = value; openTypeSelect = false"
                                >
                                    {{name}}
                                </li>
                            </ul>
                        </div>
                    </h4>

                    <button @click="showModal = false" type="button" aria-label="Close" class="close">×</button>
                </div>
            </template>

            <template v-if="tasksCount">
                <div class="modal-manage__controls">
                    <a class="link link--add-data" @click="addTask">
                        Add Task
                    </a>

                    <a v-if="tasks.length" class="link link--export" @click="exportTasks">
                        Export
                    </a>

                    <label
                        class="checkbox-completed checkbox-view"
                        :class="{'show-completed': showCompleted}"
                    >
                        <input type="checkbox" v-model="showCompleted">
                        <span></span>

                        <template v-if="showCompleted">
                            Hide Completed
                        </template>

                        <template v-else>
                            Show Completed
                        </template>
                    </label>

                    <input
                        v-model="search"
                        type="text"
                        class="modal__search"
                        placeholder="Search"
                        v-debounce:500ms.lock="getTasks"
                    />
                </div>

                <template v-if="tasks.length">


                    <div class="mb-4">
                        <div class="modal-manage__task-wrapper" v-for="task in tasks" :key="task.id">
                            <div class="modal-manage__task">
                                <Avatar
                                    :src="task.assignee.avatar"
                                    :username="task.assignee.name"
                                    :size="41"
                                />
                                <div class="modal-manage__task__text">{{task.title}}</div>
                                <div class="button-light" :class="`button-light--${task.type}`" v-html="task.object"></div>
                                <div v-if="task.due" class="button-light button-light--time" :class="{'button-light--urgent': task.urgent}">{{task.due}}</div>
                                <div>
                                    <select
                                        v-if="task.status.can_edit"
                                        class="select-status button-light"
                                        :value="task.status.status"
                                        :class="{
                                        'select-status-open': task.status.status === 'open',
                                        'select-status-blocked': task.status.status === 'blocked',
                                        'select-status-done': task.status.status === 'done',
                                        'select-status-progress': task.status.status === 'in_progress'
                                    }"
                                        @change="(e) => changeStatus(task.id, e.target.value)"
                                    >
                                        <option
                                            v-for="(name, statusId) in constants.tasksStatus"
                                            :key="statusId"
                                            :value="statusId"
                                        >
                                            {{name}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="modal-manage__task__dropdown">
                                <b-dropdown v-if="task.can_edit" class="dropdown-original icons-list dropdown-simple dropdown-small" boundary="viewport">
                                    <b-dropdown-item class="icons-list__edit" @click="editTask(task.id)">
                                        Edit Task
                                    </b-dropdown-item>

                                    <b-dropdown-item class="icons-list__delete" @click="removeTask(task.id)">
                                        Remove Task
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </div>

                    <TablePager
                        v-if="tasks.length"
                        :pages-count="gridParams.pagesCount"
                        :init-page="gridParams.currentPage"
                        @changePage="gridMethods.changePage"
                    />
                </template>
            </template>

            <div v-if="!tasks.length && listLoaded" class="dashboard__content dashboard__content--empty py-4 text-center">
                <h4 class="heading4 mb-4">Create A Task</h4>
                <p>Assign tasks to teammates (or yourself) to keep track of action items.</p>
                <b-button class="btn-primary btn-lg" @click="addTask">+ Add A Task</b-button>
            </div>

        </b-modal>

        <EditTaskModal
            ref="editTaskModal"
            :startup-id="companyType === AccountTypes.STARTUP ? companyId : 0"
            :ecosystem-id="companyType === AccountTypes.ECOSYSTEM ? companyId : 0"
            :tracker-id="trackerId"
            @saved="taskChanged"
            @closed="editTaskClosed"
        />
    </div>
</template>

<style scoped>

</style>
